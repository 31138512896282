export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAXu7USJLPe_Z3hCyGWtAzIthQtycbZZtk",
    authDomain: "restaurants2-fe2df.firebaseapp.com",
    databaseURL: "https://restaurants2-fe2df.firebaseio.com",
    projectId: "restaurants2-fe2df",
    storageBucket: "restaurants2-fe2df.appspot.com",
    messagingSenderId: "380062583748",
    appId: "1:380062583748:web:39fe3ec0fdadd9925fb291",
    measurementId: "G-XKLHB2X8BE"
  }
};