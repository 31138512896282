import { Injectable, Injector } from '@angular/core';
import PouchDB from 'pouchdb';
import pouchdbFind from 'pouchdb-find';
import { OrderDetailService } from './order-detail.service';
import { CustomerAddressService } from './customer-address.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { SharedDataService } from './shared-data.service';

PouchDB.plugin(pouchdbFind);


@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  private customersServiceListDB: any;
  private sequenceDb: any;
  private sequenceDocId = 'customersServiceList_sequence';
  private dataSubject: BehaviorSubject<any>;
  public data$: Observable<any>;

  constructor(public customerAddressServiceDb: CustomerAddressService) {
    this.customersServiceListDB = new PouchDB('customersServiceList')
    this.customersServiceListDB.createIndex({
      index: {
        fields: ['pid', 'id']
      }
    })
    this.sequenceDb = new PouchDB('mydb_sequence');

    this.dataSubject = new BehaviorSubject<any>(null);
    this.data$ = this.dataSubject.asObservable();

    this.customersServiceListDB.changes({
      live: true,
      since: 'now',
      include_docs: true
    }).on('change', (change) => {
      this.dataSubject.next(change);
    }).on('error', (error) => {
      console.error('Error:', error);
    });

  }

  public async use(fc, data) {
    if (fc == "G_DATA") {
      return this.getById(data._id)
    } else if (fc == "GA_DATA") {
      return this.getAll(data)
    } else if (fc == "IU_DATA") {
      return await this.bulkAddOrUpdate(this.customersServiceListDB, data, "phone");
    } else if (fc == "D_DATA") {
      return this.delete(data.id)
    } else if (fc == "DROP_ADD") {
      return await this.dropAdd(data);
    }
  }

  //selector === Equality operator $eq,Greater than operator $gt,Greater than or equal to operator $gte,Less than operator $lt,Less than or equal to operator $lte,In       operator $in,Not equal operator $ne,Not in operator $nin,Exists operator $exists,Type operator $type,Regex operator $regex// ex :name: { $eq: 'John' }

  public async getAll(filter) {
   
      // Query the documents and sort by the "pid" field
      return this.customersServiceListDB.find({
        selector: filter,
        //sort: [{ pid: 'asc' }]
      }).then(async result => {
        var customerAddressServiceDb = await this.customerAddressServiceDb.use('GA_DATA', {})
        
        const modifiedDocs = await Promise.all(result.docs.map(async doc => {
          const element = {
            ...doc,
            display_name: `${doc.name.trim()}${doc.lname.trim() !== '' ? ' ' + doc.lname.trim() : ''}`,
            //addresses:await this.customerAddressServiceDb.use('GA_DATA', {"user_id": doc.id })
            addresses:customerAddressServiceDb.filter(x => x.user_id == doc.id)
          };
          return element;
        }));
        return modifiedDocs;
      }).catch(error => {
        console.log(error);
      });
  }
 
  public async getById(id: string) {
    const result = await this.customersServiceListDB.get(id);
    return result;
  }


  async getNextSequence(db, sequenceName) {
    try {
      const doc = await db.get(sequenceName);
      doc.current++;
      await db.put(doc);
      return doc.current;
    } catch (error) {
      if (error.status === 404) {
        // The sequence doesn't exist, so create a new one
        await db.put({
          _id: sequenceName,
          current: 1
        });
        return 1;
      } else {
        // There was a conflict while updating the sequence
        // Retry the operation by calling the function again
        return await this.getNextSequence(db, sequenceName);
      }
    }
  }
  
  async bulkAddOrUpdate(db, docs, fieldName) {
    const existingDocs = await db.find({
      selector: {
        [fieldName]: { $in: docs.map((doc) => doc[fieldName]) },
      },
    });

    const existingDocsMap = new Map();
    existingDocs.docs.forEach((doc) => {
      existingDocsMap.set(doc[fieldName], doc);
    });

    const docsWithIds = [];
    for (const doc of docs) {
      const existingDoc = existingDocsMap.get(doc[fieldName]);
      const datatoinsert: any = {};
      if (existingDoc) {
        // Update existing doc
        datatoinsert._id = existingDoc._id;
        datatoinsert._rev = existingDoc._rev;
        datatoinsert.pid = existingDoc.pid;
        datatoinsert.id = existingDoc.id;
      } else {
        // Add new doc
        const sequence = await this.getNextSequence(this.sequenceDb, this.sequenceDocId);
        datatoinsert.pid = sequence;
        datatoinsert.id = doc.id;
      }

      if(typeof datatoinsert.id == "string"){
        datatoinsert.id = doc.id;
      }
      
      if(typeof datatoinsert.id == "string"){
        datatoinsert.id = doc.id;
      }
      datatoinsert.name = doc.name;
      datatoinsert.lname = doc.lname;
      datatoinsert.phone = doc.phone;
      datatoinsert.country_code = doc.country_code;
      datatoinsert.profile_photo_url = doc.profile_photo_url;
      

      docsWithIds.push(datatoinsert);
    }
    try {
      // Bulk add documents to the database
      const result = await db.bulkDocs(docsWithIds);
      return result;
    } catch (error) {
      console.log('Error adding documents:', error);
    }
  }

  public async delete(id: string): Promise<void> {
    let doc;
    // Fetch the document to get the current _rev value
    try {
      doc = await this.customersServiceListDB.get(id);
      // Delete the document using the current _id and _rev values
      try {
        await this.customersServiceListDB.remove(id, doc._rev);
        //console.log('Document deleted successfully!');
      } catch (err) {
        console.error('Error deleting document:', err);
      }
    } catch (err) {
      console.error('Error fetching document:', err);
      return;
    }
  }

  

  public async dropAdd(data) {
    
    return await this.customersServiceListDB.destroy().then(async () => {
      // Create a new database
      this.customersServiceListDB = new PouchDB('customersServiceList');
      return await this.bulkAddOrUpdate(this.customersServiceListDB, data, "id");
    }).then(() => {
      //console.log('Data added to the database.');
      return 0;
    }).catch((error) => {
      console.error('Error:', error);
      return 0;
    });
  }



}
