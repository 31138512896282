import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PassingDataService {

  private defaultUser = new BehaviorSubject<boolean>(false);
  public checkDefaultUser = this.defaultUser.asObservable();

  private tableOrder = new BehaviorSubject<string>("");
  public TableOrder = this.tableOrder.asObservable()

  private orderNoti = new BehaviorSubject<string>("");
  public OrderNoti = this.orderNoti.asObservable()

  private tableNoti = new BehaviorSubject<string>("");
  public TableNoti = this.tableNoti.asObservable()

  private orderDetailNoti = new BehaviorSubject<string>("");
  public OrderDetailNoti = this.orderDetailNoti.asObservable()

  private defaultAudio = new BehaviorSubject<boolean>(false);
  public checkDefaultAudio = this.defaultAudio.asObservable();

  private apiStatusUpdate = new BehaviorSubject<boolean>(false);
  public apiStatusShare = this.apiStatusUpdate.asObservable()

  constructor() { }


  defaultUserName(data) {
    this.defaultUser.next(data)
  }

  tableNewOrder(data) {
    this.tableOrder.next(data)
  }

  orderNotification(data) {
    this.orderNoti.next(data)
  }

  tableNotification(data) {
    this.tableNoti.next(data)
  }

  orderDetailNotification(data) {
    this.orderDetailNoti.next(data)
  }

  defaultRingtone(data) {
    this.defaultAudio.next(data)
  }

  CheckApiStatus(data) {
    this.apiStatusUpdate.next(data)
  }

}
