import { Injectable } from '@angular/core';
import { ReasonsService } from '../database/reasons.service';
// import { SharedDataService } from '../database/shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class DataServicesService {

  constructor(
    public reasonsServiceDb: ReasonsService,
    //  private sharedDataService: SharedDataService
    ) { 
  }

  async ngOnInit() {
    // If using a custom driver:
    // await this.storage.defineDriver(MyCustomDriver)
  }

  checkPermission(item_name) {
    let permissions = JSON.parse(localStorage.getItem('permissions'));
    if (permissions?.length > 0) {
      let check = permissions.find(x => x == item_name);
      if (check) {
        return false
      } else {
        return true
      }
    } else {
      return true;
    }
  }

  ID() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  async cartItemManage(item, selectedTenderProfile,config, action, qty_data = 1,cartIndex=-1) {  //qty_data = add,remove  // action = qty_add,qty_remove,item_remove,qty_overWrite,price_overWrite,insert_item_note,modify_cart
    
    let rawcartItems = JSON.parse(localStorage.getItem('rawCartItems'));
    if (action == "item_remove") {
      var indextoRemove = rawcartItems.findIndex(x=>x.index == item.index);
      rawcartItems.splice(indextoRemove, 1);
    }else{
      item.price = this.getItemPrice(item, selectedTenderProfile)
      item.tax_include_price = this.getItemtexIncludePrice(item, selectedTenderProfile)
      item.pricelookup_item_price = item.price
      var needToUpdateCartItemByIndex = 0;     
      var cartMatchData = -1
      if (rawcartItems) {
        if(cartIndex != -1){
          cartMatchData = cartIndex;
          needToUpdateCartItemByIndex = cartMatchData;
        }else{
          cartMatchData = rawcartItems.findIndex(x => (x.pricelookup_id == item.pricelookup_id && x.selectedAddOns.length == 0 && x.selectedRemoveAddOns.length == 0 && item.selectedAddOns.length == 0 && item.selectedRemoveAddOns.length == 0 ))
          if (cartMatchData != -1) {
            needToUpdateCartItemByIndex = cartMatchData;
          }
        }
      } else {
        rawcartItems = Array()
      }
      if (cartMatchData != -1) {
        var getCalPriceFirst = await this.getCalPriceFunction(item)
        rawcartItems[needToUpdateCartItemByIndex].addOnprice = getCalPriceFirst?.addOnprice??0
        if(action == "price_overWrite"){
            if (rawcartItems[needToUpdateCartItemByIndex].is_substitute == 1) {
              var finalOriginalPrice = Number((Number(rawcartItems[needToUpdateCartItemByIndex].pricelookup_item_price)) * Number(rawcartItems[needToUpdateCartItemByIndex].pricelookup_qty)).toFixed(2)
            } else {
              var finalOriginalPrice = Number((Number(rawcartItems[needToUpdateCartItemByIndex].pricelookup_item_price) + Number(rawcartItems[needToUpdateCartItemByIndex].addOnprice)) * Number(rawcartItems[needToUpdateCartItemByIndex].pricelookup_qty)).toFixed(2)
            }
            rawcartItems[needToUpdateCartItemByIndex].finalOriginalPrice = finalOriginalPrice;
          
          rawcartItems[needToUpdateCartItemByIndex].org_price = item.org_price
          rawcartItems[needToUpdateCartItemByIndex].price_override_item_status = item.price_override_item_status
          rawcartItems[needToUpdateCartItemByIndex].price_override_item_price = item.price_override_item_price
          rawcartItems[needToUpdateCartItemByIndex].price_override_item_id = item.price_override_item_id
          rawcartItems[needToUpdateCartItemByIndex].price_override_item_comment = item.price_override_item_comment
          rawcartItems[needToUpdateCartItemByIndex].isSpecialOffer = item.isSpecialOffer
          rawcartItems[needToUpdateCartItemByIndex].isCouponItem = item.isCouponItem
        }else if(action == "insert_item_note"){
          rawcartItems[needToUpdateCartItemByIndex].specialNote = item.specialNote
        }else if(action == "modify_cart"){
          rawcartItems[needToUpdateCartItemByIndex].selectedAddOns = item.selectedAddOns
          rawcartItems[needToUpdateCartItemByIndex].selectedRemoveAddOns = item.selectedRemoveAddOns
        }
        
        item = rawcartItems[needToUpdateCartItemByIndex];
        
      }else{
        item.price_override_item_status = 0
        item.price_override_item_price = 0
        item.price_override_item_id = 0
        item.price_override_item_comment = 0
        item.isSpecialOffer = 0
        item.isCouponItem = false
        item.pricelookup_qty = 0;
      }
  
      if (action == "qty_add") {
        item.pricelookup_qty = item.pricelookup_qty + qty_data;
      } else if (action == "qty_remove") {
        item.pricelookup_qty = item.pricelookup_qty - qty_data;
      }else if(action == "qty_overWrite"){
        item.pricelookup_qty = Number(qty_data);
      }
  
      var getCalPrice = await this.getCalPriceFunction(item)
      item.cal_price = getCalPrice.cal_price
      item.addOnprice = getCalPrice.addOnprice
      
      if (cartMatchData != -1) {
        if(item.pricelookup_qty <= 0){
          rawcartItems.splice(needToUpdateCartItemByIndex, 1);
        }else{
          rawcartItems[needToUpdateCartItemByIndex] = item;
        }
      } else {
        if(item.pricelookup_qty > 0){
          rawcartItems.push(item);
        }
      }
    }
  
    return await this.finalCalculation(rawcartItems,config)
  }

  getCalPriceFunction(item){
    item.cal_price = 0.00
    item.addOnprice = 0.00
    if (item.price_override_item_status == 1) {
      if (item.isSpecialOffer == true) {
        item.cal_price = (parseFloat(item.price_override_item_price) * parseInt(item.pricelookup_qty)).toFixed(2);
      }else{
        item.cal_price = (parseFloat(item.price_override_item_price)).toFixed(2);
      }
      
    } else {
      /* this code also in pos.page.ts selectTenderProfile() */
      if (item.is_substitute == 1) {
        item.cal_price = (parseFloat(item.price) * item.pricelookup_qty).toFixed(2)
      }else{
        if(item.selectedAddOns.length > 0){
          item.selectedAddOns.forEach(addon => {
            item.addOnprice = item.addOnprice + parseFloat(addon.price)
          });
        }
        item.cal_price = ((parseFloat(item.price) + parseFloat(item.addOnprice))  * item.pricelookup_qty).toFixed(2)
      }
      /* this code also in pos.page.ts selectTenderProfile() */
    }
    return {
      cal_price:item.cal_price,
      addOnprice:item.addOnprice
    }
  }

  getWithoutTaxAmount(withtaxamount,totaltax){
    return (withtaxamount) - ((withtaxamount*totaltax)/(100+totaltax))
  }

  fractionByPas(amount){
    let finalAmount = amount;
    var data = amount.split(".") 
    if(data[1] && Number("0."+data[1]) < 0.10){
      finalAmount = Math.round(amount)
    }
    return finalAmount
  }

  getWithTaxAmount(withouttaxamount,totaltax,qty,fractionByPasCall = 1){
    //return ((withouttaxamount*totaltax)/100)+withouttaxamount
    //below qty code is beacause of decimal diffrence if use direct price with qty
    var itemPrice = withouttaxamount/qty;
    let per = ((itemPrice*totaltax)/100);
    var itemWiseTax = per + itemPrice
    // let amount = this.fractionByPas((Math.round(itemWiseTax * 100) / 100).toFixed(2));
    let amount = itemWiseTax
    if(fractionByPasCall == 1){
      amount = this.fractionByPas(itemWiseTax.toString());  
    }
  
    return amount*qty
  }

  getPriceOfItem(item,totaltax,findTender){
    var data = {
      price_override_item_status:0,
      withoutTaxPrice:0,
      withoutTaxAddonPrice:0,
      finalItemPriceWithoutTax:0,
      pricelookup_item_price:0
    }
    
    let store_detail = JSON.parse(localStorage.getItem('store_detail'));
    
    if (findTender) {
      data.withoutTaxPrice = parseFloat(findTender.pivot.tax_include_price);
    }else{
      data.withoutTaxPrice = parseFloat(item.tax_include_price);
    }
    if(store_detail.tax_include == 1){
      data.pricelookup_item_price = data.withoutTaxPrice
    }else{
      data.pricelookup_item_price = this.getWithTaxAmount(data.withoutTaxPrice,totaltax,1)
    }
    if (item.price_override_item_status == 1) {
      data.price_override_item_status = 1;
      if(store_detail.tax_include == 1){
        data.withoutTaxPrice = item.price_override_item_price
      }else{
        data.withoutTaxPrice = this.getWithoutTaxAmount(item.price_override_item_price,totaltax)
      }
    }else{
      if (item.selectedAddOns.length > 0 && item.is_substitute != 1) {
        item.selectedAddOns.forEach(element => {
          data.withoutTaxAddonPrice += parseFloat(element.tax_include_price);//with tax
        });
      }
    }
    
    if (item?.isSpecialOffer == true) {
      data.finalItemPriceWithoutTax = (data.withoutTaxPrice) * item.pricelookup_qty  
    }else{
      if (item.price_override_item_status == 1) {
        data.finalItemPriceWithoutTax = (data.withoutTaxPrice + data.withoutTaxAddonPrice)  
      }else{
        data.finalItemPriceWithoutTax = (data.withoutTaxPrice + data.withoutTaxAddonPrice) * item.pricelookup_qty  
      }
    }
    return data;
  }

  finalCalculation(list, config) {
    if (list) {
      let sub_total = 0;
      let grand_total = 0;
      let qty = 0;
      let price_override_status = 0;
      let taxList = [];
      let taxListExtra = [];
      let selectedTender = JSON.parse(localStorage.getItem('selectedTenderProfile'));
     

      let round_off_flag: number = Number(localStorage.getItem('is_round_off'));
      let store_detail = JSON.parse(localStorage.getItem('store_detail'));
      if (list.length == 0) {
        config.discount_data.id = 0;
        config.discount_data.discount_rate = 0;
        config.discount_data.discount_type = 0;
        config.isDiscountApply = false;
      }
      

      let number_of_item_with_rounfoff = 0
      let totalDiscount = 0
      let item_roundoff_tax_cal_array = []
      let item_tax_cal_array = []
      
      var totalOfAllItemPriceWithTax = 0;
      var totalOfAllItemPrice = 0;
      list.forEach(listelement => {
        
        let stTax = []
        if(typeof listelement.store_tax === 'string'){
          stTax = JSON.parse(listelement?.store_tax)
        }else{
          stTax = listelement.store_tax
        }
        let totaltax:any = 0;
        let findTender;
        let tender_profiles = [];
        if (stTax.length > 0) {
          stTax.forEach(element => {
            totaltax = Number(totaltax) + Number(element.tax_value)
          });
        }
        if (typeof listelement.tender_profiles === 'string') {
          tender_profiles = JSON.parse(listelement.tender_profiles);
        }else{
          tender_profiles = listelement.tender_profiles;
        }
       
        if (tender_profiles?.length > 0) {
          findTender = tender_profiles.find(x => x.name === selectedTender.name && Number(x.pivot.price) > 0);
        }

        var itemData = this.getPriceOfItem(listelement,totaltax,findTender);
        totalOfAllItemPrice += itemData.finalItemPriceWithoutTax;
        
        totalOfAllItemPriceWithTax += this.getWithTaxAmount(itemData.finalItemPriceWithoutTax,totaltax,1);
      });
      var discountPerc = 0;
      if (config.isDiscountApply) {
        if (parseInt(config.discount_data.discount_type) == 1) {
          if(store_detail.tax_include == 1){
            discountPerc = parseFloat(config.discount_data.discount_rate) / totalOfAllItemPrice
          }else{
            discountPerc = parseFloat(config.discount_data.discount_rate) / totalOfAllItemPriceWithTax
          }
        }else{
          discountPerc = parseFloat(config.discount_data.discount_rate)
        }
      }
      
      var index = 0
      list.forEach(item => {
        item.index = index;
        index++;
        //store_detail.tax_include  1 = excuded,0=included
        qty += parseInt(item.pricelookup_qty);
        var withoutTaxPrice = 0;
        var withoutTaxAddonPrice = 0;
        let finalItemPriceWithoutTax = 0;
        let finalItemPriceWithoutTaxOrg = 0;
        let findTender;
        let totaltax:any = 0;
        
        let stTax = item.store_tax
        
        
        let tender_profiles = [];
        let calAddonprice = 0;

        tender_profiles = item.tender_profiles;

        if (tender_profiles?.length > 0) {
          findTender = tender_profiles.find(x => x.name === selectedTender.name && Number(x.pivot.price) > 0);
        }
        
        if (stTax.length > 0) {
          stTax.forEach(element => {
            totaltax = Number(totaltax) + Number(element.tax_value)
          });
        }

        let extra_tax = item.extra_tax
        var extraTaxArray = [];
        if(extra_tax && extra_tax.length > 0){
          extraTaxArray = extra_tax.filter(x=>x.tender_profiles_store_id == selectedTender.id)
        }
        var itemData = this.getPriceOfItem(item,totaltax,findTender);
        if(price_override_status == 0 && itemData.price_override_item_status == 1){
          price_override_status = 1
        }
        withoutTaxPrice = itemData.withoutTaxPrice
        finalItemPriceWithoutTaxOrg = itemData.finalItemPriceWithoutTax
        finalItemPriceWithoutTax = itemData.finalItemPriceWithoutTax
        withoutTaxAddonPrice = itemData.withoutTaxAddonPrice

        item.pricelookup_item_price = itemData.pricelookup_item_price;
        item.selectedAddOns.map(x => {
          x.addons_id = x.id
          return x
        }) 
        var discountAmount = 0
        var afterDiscountAmount = 0
        //discount code
        if (config.isDiscountApply) {
          if (parseInt(config.discount_data.discount_type) == 1) {
            discountAmount = (finalItemPriceWithoutTax * discountPerc)
            afterDiscountAmount = finalItemPriceWithoutTax - discountAmount
          }else{
            discountAmount = ((finalItemPriceWithoutTax * discountPerc) / 100)
            afterDiscountAmount = finalItemPriceWithoutTax - discountAmount
          }
          if(store_detail.tax_include == 1){
            totalDiscount += discountAmount;
          }else{
            totalDiscount += this.getWithTaxAmount(discountAmount,totaltax,1,0)
          }
          finalItemPriceWithoutTax = afterDiscountAmount;
        }

        let newstTaxExtra = [];
        let newstTax = [];
        let extraTaxAmount = 0;
        if(extraTaxArray.length > 0){
          extraTaxArray.forEach(extraTaxArray_element => {
            var tax_data = extraTaxArray_element.tax_store
            tax_data.tax_cal = 0;
            let per = 0;
            per = (Number(finalItemPriceWithoutTax) * parseFloat(tax_data.tax_value)) / 100;
            if(per < 0){
              per = 0;
            }
            extraTaxAmount += per;
            tax_data.tax_amount = per;
            tax_data.tax_cal = per;
            tax_data.tax_id = extraTaxArray_element.tax_id;
            tax_data.tender_profiles_store_id = extraTaxArray_element.tender_profiles_store_id;
            tax_data.name = tax_data.display_name;
            tax_data.standard_price = item.standard_price;
            tax_data.tax_include_price = item.tax_include_price;
            tax_data.type = 1;
            taxListExtra.push(tax_data)
            newstTaxExtra.push(tax_data)
          });
        }
        if (item?.store_tax?.length > 0) {
          stTax.forEach(element => {
            
            if (round_off_flag == 1) {
              if (item_roundoff_tax_cal_array[element.tax_value]) {
                item_roundoff_tax_cal_array[element.tax_value] = item_roundoff_tax_cal_array[element.tax_value] + 1;
              } else {
                item_roundoff_tax_cal_array[element.tax_value] = 1;
              }
              number_of_item_with_rounfoff = number_of_item_with_rounfoff + 1;
            } 
            if (item_tax_cal_array[element.tax_value]) {
              item_tax_cal_array[element.tax_value] = item_tax_cal_array[element.tax_value] + 1;
            } else {
              item_tax_cal_array[element.tax_value] = 1;
            }
          })

          stTax.forEach(element => {
            let per = 0;
            per = ((finalItemPriceWithoutTax + extraTaxAmount) * parseFloat(element.tax_value)) / 100;
            if(per < 0){
              per = 0;
            }
            element.tax_cal = per;
            element.tax_amount = per;
            element.tax_id = element.pivot.tax_id;
            element.name = element.display_name;
            element.standard_price = item.standard_price;
            element.tax_include_price = item.tax_include_price;
            element.type = 0;
            taxList.push(element)
            newstTax.push(element)
          });
        }
        item.taxes = newstTax;//adding new tax to item
        item.taxesExtra = newstTaxExtra
        item.order_detail_tax = [...newstTaxExtra,...newstTax];//adding new tax to item

        if(store_detail.tax_include == 1){
          sub_total += parseFloat(finalItemPriceWithoutTaxOrg.toFixed(2));
          item.cal_price = parseFloat(finalItemPriceWithoutTaxOrg.toFixed(2)).toFixed(2);
        }else{
          var useqty = parseInt(item.pricelookup_qty);
          if (item?.isSpecialOffer == true) {
            useqty = 1
          }          
          var withtaxamountforSubTotal = this.getWithTaxAmount(finalItemPriceWithoutTaxOrg,totaltax,useqty)
          sub_total += parseFloat(withtaxamountforSubTotal.toFixed(2));
          item.cal_price = withtaxamountforSubTotal.toFixed(2);
        }
      });
      grand_total = parseFloat(sub_total.toFixed(2));
      grand_total = grand_total - totalDiscount;
      let final_totaltax = 0;
      let roundvalue: any = grand_total;
      let roundOff = 0;
      
      if(sub_total > 0){
        if(store_detail.tax_include == 1){
        
          if(taxList.length > 0){
            grand_total += taxList.reduce((accumulator, object) => {
              return accumulator + object.tax_amount;
            }, 0);
          }
          if(taxListExtra.length > 0){
            grand_total += taxListExtra.reduce((accumulator, object) => {
              return accumulator + object.tax_amount;
            }, 0);
          }
        }

        taxList.forEach(element => {
          final_totaltax = Number(final_totaltax) + Number(element.tax_value)
        })

        roundvalue = grand_total;
        if (round_off_flag == 1) {
          roundvalue = Math.round(grand_total);
          roundOff = Math.abs(roundvalue - grand_total);

          taxList.forEach(tax_list_array => {
            if (Number(roundvalue) > grand_total) {
              tax_list_array.tax_cal += ((((roundOff * (item_roundoff_tax_cal_array[tax_list_array.tax_value] / number_of_item_with_rounfoff)) * 100) / (100 + final_totaltax)) * tax_list_array.tax_value) / 100
            } else {
              tax_list_array.tax_cal -= ((((roundOff * (item_roundoff_tax_cal_array[tax_list_array.tax_value] / number_of_item_with_rounfoff)) * 100) / (100 + final_totaltax)) * tax_list_array.tax_value) / 100
            }
          })
        }
      }
     if(roundvalue < 0){
      roundvalue = 0
     }
      taxList = [ ...taxListExtra, ...taxList];
      let data = {
        products: list,
        sub_total: sub_total.toFixed(2),
        tax_amt: this.getTaxAmt(taxList),
        grand_total: parseFloat(roundvalue.toFixed(2)),
        roundOff: parseFloat(roundOff.toFixed(2)),
        total_qty: qty,
        discountAmt: parseFloat(totalDiscount.toFixed(2)),
        price_override_status: price_override_status,
        tax_array: taxList,
        discount_data: config.discount_data,
        isDiscountApply: config.isDiscountApply
      }
      
      localStorage.setItem('rawCartItems', JSON.stringify(list));
      return data;
    }else{
      return {
        products: [],
        sub_total: 0.00,
        tax_amt: 0.00,
        grand_total: 0.00,
        roundOff: 0.00,
        total_qty: 0,
        discountAmt: 0.00,
        price_override_status: 0,
        tax_array: [],
        discount_data: {},
        isDiscountApply: 0
      }
    }
  }

  getItemPrice(item, selectedTenderProfile) {
    let price;
    let itemTenderProfile = item.tender_profiles;
    if (itemTenderProfile.length > 0) {
      let findTender = itemTenderProfile.find(x => x.name === selectedTenderProfile.name);
      if (findTender) {
        if(findTender.pivot.price > 0){
          price = parseFloat(findTender.pivot.price).toFixed(2);  
        }else{
          price = parseFloat(item.standard_price).toFixed(2);
        }
      } else {
        price = parseFloat(item.standard_price).toFixed(2);
      }
    } else {
      price = parseFloat(item.standard_price).toFixed(2);
    }
    return price;
  }

  getItemtexIncludePrice(item, selectedTenderProfile) {
    let tax_include_price;
    let itemTenderProfile = item.tender_profiles;
    if (itemTenderProfile.length > 0) {
      let findTender = itemTenderProfile.find(x => x.name === selectedTenderProfile.name);
      if (findTender) {
        if(findTender.pivot.tax_include_price > 0){
          tax_include_price = parseFloat(findTender.pivot.tax_include_price);
        }else{
          tax_include_price = parseFloat(item.tax_include_price);
        }
      } else {
        tax_include_price = parseFloat(item.tax_include_price);
      }
    } else {
      tax_include_price = parseFloat(item.tax_include_price);
    }
    return tax_include_price;
  }

  getTaxAmt(taxList) {
    let cal = 0;
    taxList.forEach(element => {
      cal += element.tax_cal;
    });
    return cal.toFixed(2);
  }

}
