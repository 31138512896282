import { Injectable, NgZone } from '@angular/core';
import { AppComponent } from '../app.component';
import { PosService } from './pos.service';
import { appConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
// import { StoreUserAttendanceService } from '../database/store-user-attendance.service';
// import { PosMenuListService } from '../database/pos-menu-list.service';
import { TenderProfilesStoreService } from '../database/tender-profiles-store.service';
import { TenderTypesService } from '../database/tender-types.service';
import { PluCategoryListService } from '../database/plu-category-list.service';
// import { ReasonsService } from '../database/reasons.service';
import { DiscountService } from '../database/discount.service';
import { StoreTableCategoriesService } from '../database/store-table-categories.service';
import { StoreTablesService } from '../database/store-tables.service';
// import { RidersService } from '../database/riders.service';
import { StorePrintersService } from '../database/store-printers.service';
import { PluMenuItemService } from '../database/plu-menu-item.service';
import { OrdersService } from '../database/orders.service';
import { OrderDetailService } from '../database/order-detail.service';
import { CustomersService } from '../database/customers.service';
import { CustomerAddressService } from '../database/customer-address.service';
import { GeneralService } from '../database/general.service';
// import { interval, Subscription } from 'rxjs'
import { PrintService } from './print.service';
// import { DataServicesService } from './data-services.service';
import { Router } from '@angular/router';
// import { PaidOutService } from '../database/paid-out.service';
// import { CashOutService } from '../database/cash-out.service';
import { SharedDataService } from '../database/shared-data.service';
import { Network } from '@capacitor/network';
import { PassingDataService } from './passing-data.service';
// import { registerPlugin } from '@capacitor/core';

// export interface JavaSocketPlugin {
//   sendMessageFromClientSocket(options: { message: object, }): Promise<{ message: object, }>;
// }
// const JavaSocket = registerPlugin<JavaSocketPlugin>('JavaSocket');

// export default JavaSocket;

// export interface SocketIOPlugin {
//   sendMessage(options: {}): Promise<{}>;
// }
// const SocketIO = registerPlugin<SocketIOPlugin>('SocketIO');

@Injectable({
  providedIn: 'root'
})
export class WebSqlService {

  currentUser: any = {};
  private queue: (() => Promise<any>)[] = [];
  private isProcessing: boolean = false;
  private queueSyncOrder: (() => Promise<any>)[] = [];
  private isProcessingSyncOrder: boolean = false;
  private queueSyncTable: (() => Promise<any>)[] = [];
  private isProcessingSyncTable: boolean = false;
  storePosBehavior: any = {};
  kitchenstationList: any = [];
  internetStatus: any = "Online";
  public checkApiStatus: any = []
  public isSyncOrder: boolean = true;
  public isExecutingInterval = true;

  constructor(
    public appComponent: AppComponent,
    private _posService: PosService,
    private http: HttpClient,
    public toastr: ToastrService,
    private datePipe: DatePipe,
    private translate: TranslateService,
    // public storeUserAttendanceServiceDb: StoreUserAttendanceService,
    // public posMenuListServiceDb: PosMenuListService,
    public tenderProfilesStoreServiceDb: TenderProfilesStoreService,
    public tenderTypesServiceDb: TenderTypesService,
    public pluCategoryListServiceDb: PluCategoryListService,
    // public rasonsServiceDb: ReasonsService,
    public discountServiceDb: DiscountService,
    public storeTableCategoriesServiceDb: StoreTableCategoriesService,
    public storeTablesServiceDb: StoreTablesService,
    // public ridersServiceDb: RidersService,
    public storePrintersServiceDb: StorePrintersService,
    public pluMenuItemServiceDb: PluMenuItemService,
    public ordersServiceDb: OrdersService,
    public orderDetailServiceDb: OrderDetailService,
    public customersServiceDb: CustomersService,
    public customerAddressServiceDb: CustomerAddressService,
    public generalServiceDb: GeneralService,
    public print: PrintService,
    // public _dataService: DataServicesService,
    private _router: Router,
    // public paidOutServiceDb: PaidOutService,
    // public cashOutServiceDb: CashOutService,
    private sharedDataService: SharedDataService,
    private zone: NgZone,
    private passData: PassingDataService
  ) {

    // this.zone.runOutsideAngular(() => {
    //   window.setInterval(async () => {
    //     if (this.isExecutingInterval == true) {
    //       if (this.internetStatus != "Offline") {
    //         if (this.isSyncOrder == true) {
    //           await this.syncOrder();
    //           await this.syncTables();
    //         }
    //       }
    //     }
    //   }, 30000)
    // });

    this.zone.runOutsideAngular(() => {
      window.setInterval(async () => {
        if (this.isExecutingInterval == true) {
          if (this.internetStatus != "Offline") {
            if (this.isSyncOrder == true) {
              await this.syncOrder();
              await this.syncTables();
            }
          }
        }
      }, 7000)
    });

    this.zone.run(async () => {
      const status = await Network.getStatus();
      if (status.connected == false) {
        this.internetStatus = 'Offline';
      } else {
        this.internetStatus = 'Online';
      }

      Network.addListener('networkStatusChange', status => {
        if (status.connected == false) {
          this.internetStatus = 'Offline';
        } else {
          this.internetStatus = 'Online';
        }
      });
    });
  }

  removeLocalStorageCartInfo() {
    localStorage.removeItem('rawCartItems');
    localStorage.removeItem('appliedCoupon');
    localStorage.removeItem('selectedTenderProfile');
    localStorage.removeItem('reCallOrderData');
    localStorage.removeItem('reCallOrder');
    localStorage.removeItem("adjust_reenter_order_id")
    localStorage.removeItem("adjust_reenter_order_ref")
    localStorage.removeItem("adjust_reenter_order_ref_data")
    localStorage.removeItem("adjust_reenter_order_reason")
    localStorage.removeItem("mycart");
    localStorage.removeItem("selectedTable");
    localStorage.removeItem("reOrder");
  }

  async startWebsqlStoreFunction() {
    this.appComponent.spinnerActivate('syncspinner');
    try {
      this.isExecutingInterval = false;
      var apiStatus = [
        {
          name: "tenderlist",
          value: 0
        },
        {
          name: "tendertypelist",
          value: 0
        },
        {
          name: "plucategorylist",
          value: 0
        },
        {
          name: "plumenuitem",
          value: 0
        },
        {
          name: "orderlistsync",
          value: 0
        },
        {
          name: "storereceipttemplate",
          value: 0
        },
        {
          name: "getstoreposbehavior",
          value: 0
        },
        {
          name: "storetablecategories",
          value: 0
        },
        {
          name: "storetables",
          value: 0
        },
        {
          name: "storeprinters",
          value: 0
        },
        {
          name: "coupondiscounts",
          value: 0
        },
      ]

      localStorage.setItem('checkApiStatus', JSON.stringify(apiStatus))
      this.checkApiStatus = apiStatus

      this.sharedDataService.removeSharedData();
      localStorage.setItem('workingOnStartOfDay', '1');
      await this.tenderProfilesStoreServiceDb.use('DROP_ADD', [])
      await this.tenderTypesServiceDb.use('DROP_ADD', [])
      await this.pluCategoryListServiceDb.use('DROP_ADD', [])
      await this.pluMenuItemServiceDb.use('DROP_ADD', [])
      await this.ordersServiceDb.use('DROP_ADD', [])
      await this.customersServiceDb.use('DROP_ADD', [])
      await this.customerAddressServiceDb.use('DROP_ADD', [])
      await this.storeTableCategoriesServiceDb.use('DROP_ADD', [])
      await this.storeTablesServiceDb.use('DROP_ADD', [])
      await this.storePrintersServiceDb.use('DROP_ADD', [])
      await this.discountServiceDb.use('DROP_ADD', [])

      this.countryList()
      this.getCouponList();
      this.kitchenStationList();
      await this.syncfromMobileOrdersFirstTime()
      await this.getTenderProfile()
      await this.getTenderTypeListItems()
      await this.getPLUCategoryList()
      await this.getPluMenuItem()
      await this.storeTableCategories()
      await this.storeTables()
      await this.getstorePosBehavior()
      await this.getPrinterList()
      await this.storeReceiptTemplate()
      await this.insertStoreCustomer()

      console.log("Websql data added..!");
      let last_sync_time = this.datePipe.transform(new Date(), 'MM/dd/yyyy, HH:mm:ss');
      localStorage.setItem('last_sync_time', JSON.stringify(last_sync_time.toLocaleString()));
      localStorage.setItem('workingOnStartOfDay', '0');
      localStorage.setItem('reasonsToSync', "[]");
      var apiStatusName = this.checkApiStatus.filter(x => x.value == 0);
      if (apiStatusName.length == 0) {
        localStorage.setItem('SyncCompleted', JSON.stringify(true))
        this.passData.CheckApiStatus(true)
      } else {
        apiStatusName.forEach(async element => {
          if (element.name == "tenderlist") {
            await this.getTenderProfile()
          } else if (element.name == "tendertypelist") {
            await this.getTenderTypeListItems()
          } else if (element.name == "plucategorylist") {
            await this.getPLUCategoryList()
          } else if (element.name == "plumenuitem") {
            await this.getPluMenuItem()
          } else if (element.name == "orderlistsync") {
            await this.syncfromMobileOrdersFirstTime()
          } else if (element.name == "storereceipttemplate") {
            await this.storeReceiptTemplate()
          } else if (element.name == "getstoreposbehavior") {
            await this.getstorePosBehavior()
          } else if (element.name == "storetablecategories") {
            await this.storeTableCategories()
          } else if (element.name == "storetables") {
            await this.storeTables()
          } else if (element.name == "storeprinters") {
            await this.getPrinterList()
          } else if (element.name == "coupondiscounts") {
            await this.getCouponList()
          }
        });
        var apiStatusName = this.checkApiStatus.filter(x => x.value == 0);
        if (apiStatusName.length == 0) {
          localStorage.setItem('SyncCompleted', JSON.stringify(true))
          this.passData.CheckApiStatus(true)
        } else {
          apiStatusName.forEach(async element => {
            if (element.name == "tenderlist") {
              await this.getTenderProfile()
            } else if (element.name == "tendertypelist") {
              await this.getTenderTypeListItems()
            } else if (element.name == "plucategorylist") {
              await this.getPLUCategoryList()
            } else if (element.name == "plumenuitem") {
              await this.getPluMenuItem()
            } else if (element.name == "orderlistsync") {
              await this.syncfromMobileOrdersFirstTime()
            } else if (element.name == "storereceipttemplate") {
              await this.storeReceiptTemplate()
            } else if (element.name == "getstoreposbehavior") {
              await this.getstorePosBehavior()
            } else if (element.name == "storetablecategories") {
              await this.storeTableCategories()
            } else if (element.name == "storetables") {
              await this.storeTables()
            } else if (element.name == "storeprinters") {
              await this.getPrinterList()
            } else if (element.name == "coupondiscounts") {
              await this.getCouponList()
            }
          });
        }
        localStorage.setItem('SyncCompleted', JSON.stringify(false))
        localStorage.setItem('checkApiStatus', JSON.stringify(this.checkApiStatus))
        this.passData.CheckApiStatus(false)
      }

      this.appComponent.spinnerDeactivate('syncspinner');
      this.isExecutingInterval = true;
      return 1;
    } catch (error) {
      console.log(error);
      this.appComponent.spinnerDeactivate('syncspinner');
      this.isExecutingInterval = true;
      return 0;
    }
  }

  countryList() {
    try {
      return this._posService.countryList().subscribe({
        next: async (res) => {
          if (res.status == 1) {
            localStorage.setItem('countryList', JSON.stringify(res.data))
            return 0;
          } else {
            console.log("error:", res.status);
            return 0;
          }
        },
        error: (error) => {
          console.log(error);
          return 0;
        }
      });
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  getTenderProfile() {
    return new Promise(resolve => {

      try {
        this.http.post(appConfig.apiUrl + '/tenderlist', '').subscribe({
          next: async (res) => {
            this.checkApiStatus.find(x => x.name == "tenderlist").value = 1;
            if (res['status'] == 1) {
              if (res['data'].length > 0) {
                var checktableAllow = res['data'].findIndex(x => x.is_table_allow == 1);
                if (checktableAllow == -1) {
                  localStorage.setItem('is_table_allow', JSON.stringify(false))
                } else {
                  localStorage.setItem('is_table_allow', JSON.stringify(true))
                }
                //localStorage.setItem('tenderProfilesStoreServiceDb',JSON.stringify(res['data']))
                await this.tenderProfilesStoreServiceDb.use('IU_DATA', res['data'])
                await this.sharedDataService.getTenderProfilesListArray();
              }
              resolve('')
            } else {
              this.toastr.error('', res['message']);
              resolve('')
            }
          },
          error: (error) => {
            console.log(error);
            resolve('')
          }
        });
      } catch (error) {
        console.log(error);
        resolve('')
      }
    });
  }

  getTenderTypeListItems() {
    return new Promise(resolve => {

      try {
        this.http.post(appConfig.apiUrl + '/tendertypelist', { department_id: 1 }).subscribe({
          next: async (res) => {
            this.checkApiStatus.find(x => x.name == "tendertypelist").value = 1;
            if (res['status'] == 1) {
              if (res['data'].length > 0) {
                await this.tenderTypesServiceDb.use('IU_DATA', res['data'])
              }
              resolve('')
            } else {
              this.toastr.error('', res['message']);
              resolve('')
            }
          },
          error: (error) => {
            console.log(error);
            resolve('')
          }
        });
      } catch (error) {
        console.log(error);
        resolve('')
      }
    });
  }

  getPLUCategoryList() {
    return new Promise(resolve => {
      try {
        this.http.post(appConfig.apiUrl + '/plucategorylist', {}).subscribe({
          next: async (res) => {
            this.checkApiStatus.find(x => x.name == "plucategorylist").value = 1;
            if (res['status'] == 1) {
              if (res['data'].length > 0) {
                await this.pluCategoryListServiceDb.use('IU_DATA', res['data'])
                this.sharedDataService.getPluCategoryListArray();
              }
              resolve('')
            } else {
              this.toastr.error('', res['message']);
              resolve('')
            }
          }, error: (error) => {
            console.log(error);
            resolve('')
          }
        });
      } catch (error) {
        console.log(error);
        resolve('')
      }
    })
  }

  getPluMenuItem() {
    return new Promise(resolve => {
      try {
        this.http.post(appConfig.apiUrl + '/plumenuitem', {}).subscribe({
          next: async (res) => {
            this.checkApiStatus.find(x => x.name == "plumenuitem").value = 1;
            if (res['status'] == 1) {
              if (res['data'].length > 0) {
                await this.pluMenuItemServiceDb.use('IU_DATA', res['data'])
                this.sharedDataService.getPluMenuItemListArray();
              }
              resolve('')
            }
            else {
              resolve('')
            }
          }, error: (error) => {
            console.log(error);
            resolve('')
          }
        });
      } catch (error) {
        console.log(error);
        resolve('')
      }
    })
  }

  getDiscount() {
    return new Promise(resolve => {
      try {
        this.http.post(appConfig.apiUrl + '/discountlist', '').subscribe({
          next: async (res) => {
            if (res['status'] == 1) {
              if (res['data'].length > 0) {
                await this.discountServiceDb.use('IU_DATA', res['data'])
              }
              resolve('')
            } else {
              resolve('')
            }
          }, error: (error) => {
            console.log(error);
            resolve('')
          }
        });
      } catch (error) {
        console.log(error);
        resolve('')
      }
    });
  }

  storeTableCategories(callFrom = 0) {
    return new Promise(resolve => {
      try {
        this.http.post(appConfig.apiUrl + '/table-categories', '').subscribe({
          next: async (res) => {
            if (callFrom == 0) {
              this.checkApiStatus.find(x => x.name == "storetablecategories").value = 1;
            }
            if (res['status'] == 1) {
              if (res['data'].length > 0) {
                await this.storeTableCategoriesServiceDb.use('IU_DATA', res['data'])
              }
              resolve('')
            } else {
              resolve('')
            }
          }, error: (error) => {
            console.log(error);
            resolve('')
          }
        });
      } catch (error) {
        console.log(error);
        resolve('')
      }
    });
  }

  storeTables(callFrom = 0) {
    return new Promise(resolve => {
      try {
        this.http.post(appConfig.apiUrl + '/table-list', '').subscribe({
          next: async (res) => {
            if (callFrom == 0) {
              this.checkApiStatus.find(x => x.name == "storetables").value = 1;
            }
            if (res['status'] == 1) {
              if (res['data'].length > 0) {
                res['data'].map(x => x.isSync = 1)
                this.appComponent.stopDBChanges();
                this.ordersServiceDb.closeDbOnSync();
                await this.storeTablesServiceDb.use('IU_DATA', res['data']).then(async (res) => {
                  let table = await this.storeTablesServiceDb.use('G_DATA', { "_id": res[0].id });

                  if (this.internetStatus != "Offline" && table.isSync == 0) {
                    // console.log('555');
                    this.syncTables(table.pid)
                  }
                });
                // await this.storeTablesServiceDb.use('IU_DATA', res['data'])
                this.appComponent.startDBChanges();
              }
              resolve('')
            } else {
              resolve('')
            }
          }, error: (error) => {
            console.log(error);
            resolve('')
          }
        });
      } catch (error) {
        console.log(error);
        resolve('')
      }
    });
  }

  storeReceiptTemplate() {
    return new Promise(resolve => {
      try {
        var data = {
          store_id: localStorage.getItem('store_id')
        }
        this._posService.storeReceiptTemplate(data)
          .subscribe({
            next: async (res) => {
              this.checkApiStatus.find(x => x.name == "storereceipttemplate").value = 1;
              if (res.status == 1) {
                localStorage.setItem('storeReceiptData', JSON.stringify(res.data))
              }
              resolve('')
            },
            error: (error) => {
              console.log(error);
              resolve('')
            }
          });
      } catch (error) {
        console.log(error);
        resolve('')
      }
    });
  }

  kitchenStationList() {
    return new Promise(resolve => {
      try {
        this.http.post(appConfig.apiUrl + '/getkitchenstation', '').subscribe({
          next: async (res) => {
            if (res['status'] == 1) {
              if (res['data'].length > 0) {
                this.kitchenstationList = res['data']
                localStorage.setItem('kitchenstationList', JSON.stringify(this.kitchenstationList))
              }
              resolve('')
            } else {
              resolve('')
            }
          }, error: (error) => {
            console.log(error);
            resolve('')
          }
        });
      } catch (error) {
        console.log(error);
        resolve('')
      }
    })
  }

  async insertStoreCustomer() {
    var store_detail = JSON.parse(localStorage.getItem('store_detail'));
    var store_customer = {
      id: store_detail.default_customer.id,
      name: store_detail.default_customer.name,
      lname: store_detail.default_customer.lname,
      phone: store_detail.default_customer.phone,
      country_code: store_detail.code,
      profile_photo_url: store_detail.profile_photo_url
    }
    await this.insertUpdateCustomerAndAddress(store_customer, store_detail.default_customer.addresses)
  }

  ID() {
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  formateDate(date, format) {
    return this.datePipe.transform(date, format);
  }

  getPrinterList() {
    return new Promise(resolve => {
      try {
        // var terminal_id = localStorage.getItem('terminal_id')
        var store_id = localStorage.getItem('store_id')
        this.http.post(appConfig.apiUrl + '/storeprinters', { store_id: store_id}).subscribe({
          next: async (res) => {
            this.checkApiStatus.find(x => x.name == "storeprinters").value = 1;
            if (res['status'] == 1) {
              if (res['data'].length > 0) {
                await this.storePrintersServiceDb.use('IU_DATA', res['data'])
              }
              resolve('')
            } else {
              this.toastr.error('', res['message']);
              resolve('')
            }
          },
          error: (error) => {
            console.log(error);
            resolve('')
          }
        });
      } catch (error) {
        console.log(error);
        resolve('')
      }
    });
  }

  getstorePosBehavior(callFrom = 0) {
    return new Promise(resolve => {
      try {

        var store_id = localStorage.getItem('store_id')
        this.http.post(appConfig.apiUrl + '/getstoreposbehavior', { store_id: store_id }).subscribe({
          next: async (res) => {
            if (callFrom == 0) {
              this.checkApiStatus.find(x => x.name == "getstoreposbehavior").value = 1;
            }
            if (res['status'] == 1) {
              localStorage.setItem("is_login_with_pin", res['data'].is_login_with_pin);
              localStorage.setItem('storePosBehavior', JSON.stringify(res['data']))
              resolve('')
            } else {
              this.toastr.error('', res['message']);
              resolve('')
            }
          },
          error: (error) => {
            console.log(error);
            resolve('')
          }
        });
      } catch (error) {
        console.log(error);
        resolve('')
      }
    });
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  getCouponList() {
    return new Promise(async resolve => {
      var store_id = localStorage.getItem('store_id')
      localStorage.removeItem('coupons');
      this.http.post(appConfig.apiUrl + '/coupon_discounts', { store_id: store_id }).subscribe({
        next: async (res) => {
          this.checkApiStatus.find(x => x.name == "coupondiscounts").value = 1;
          if (res['status'] == 1) {
            if (res['data'].length > 0) {
              localStorage.setItem('original_coupons', JSON.stringify(res['data']));
              var getCoupons = res['data'];
              var coupons = [];
              var clerks = [];
              await getCoupons.forEach(async getCoupons_element => {

                if (getCoupons_element.status == 1) {
                  var getCoupon_element = [];
                  var conditions = [];
                  var coupon_discounts_conditions_names = getCoupons_element.coupon_discounts_conditions_names;
                  if (coupon_discounts_conditions_names) {

                    await coupon_discounts_conditions_names.forEach(async coupon_discounts_conditions_names_element => {
                      var coupon_condition = {};
                      var coupon_discounts_conditions = coupon_discounts_conditions_names_element.coupon_discounts_conditions;
                      if (coupon_discounts_conditions) {
                        var items = [];

                        await coupon_discounts_conditions.forEach(coupon_discounts_conditions_element => {
                          var coupon_discounts_conditions_items_only = coupon_discounts_conditions_element.coupon_discounts_conditions_items_only;
                          if (coupon_discounts_conditions_items_only) {
                            var items_data = []
                            coupon_discounts_conditions_items_only.forEach(items_element => {
                              items_data.push({
                                'plu_id': items_element.plu_id,
                                'pricelookup_id': items_element.pricelookup_id,
                                'item_name': items_element.item_name,
                                'standard_price': items_element.standard_price
                              })
                            });

                            coupon_condition = {
                              'discount_type': coupon_discounts_conditions_element.discount_type,
                              'no_of_section': coupon_discounts_conditions_element.no_of_section,
                              'amount': coupon_discounts_conditions_element.amount,
                              'is_all_items': coupon_discounts_conditions_element.is_all_items,
                              'department_id': coupon_discounts_conditions_element.coupon_discounts_conditions_departments_only.department_id,
                              'items': items_data
                            }
                          }
                          items.push(coupon_condition);
                        });
                      }
                      if (items) {
                        conditions.push(items);
                      }
                    });

                  }
                  if (getCoupons_element.is_all_clerks != 1) {
                    if (getCoupons_element.coupon_discounts_clerks.length > 0) {
                      getCoupons_element.coupon_discounts_clerks.forEach(clerk => {
                        clerks.push(clerk.id)
                      });
                    }
                  }
                  coupons.push({
                    'id': getCoupons_element.id,
                    'name': getCoupons_element.name,
                    'offer_type': getCoupons_element.offer_type,
                    'discount_code': getCoupons_element.discount_code,
                    'continue_ring_up': getCoupons_element.continue_ring_up,
                    'apply_min_order_value': getCoupons_element.apply_min_order_value,
                    'min_order_value': getCoupons_element.min_order_value,
                    'apply_max_order_value': getCoupons_element.apply_max_order_value,
                    'max_order_value': getCoupons_element.max_order_value,
                    'whole_order_discount': getCoupons_element.whole_order_discount,
                    'discount_type': getCoupons_element.discount_type,
                    'discount_amount': getCoupons_element.discount_amount,
                    'max_discount_amount': getCoupons_element.max_discount_amount,
                    'pick_value': getCoupons_element.pick_value,
                    'is_all_clerks': getCoupons_element.is_all_clerks,
                    'is_password_protected': getCoupons_element.is_password_protected,
                    'auto_discount_on_checkout': getCoupons_element.auto_discount_on_checkout,
                    'password': getCoupons_element.password,
                    'apply_discount_schedule_days': getCoupons_element.apply_discount_schedule_days,
                    'coupon_discounts_timeline': getCoupons_element.coupon_discounts_timeline,
                    'apply_discount_schedule_date_range': getCoupons_element.apply_discount_schedule_date_range,
                    'clerks': clerks,
                    'condition': conditions,
                  })
                }
              });
              localStorage.setItem('coupons', JSON.stringify(coupons));
            }
            resolve('')
          } else {
            resolve('')
          }
        }, error: (error) => {
          console.log(error);
          resolve('')
        }
      });
    });
  }

  //start sync online or miss order
  syncfromMobileOrders(callFrom = 0) {
    return new Promise(async resolve => {
      let onlineSendData = {
        // "terminal_id": localStorage.getItem("terminal_id"),
        "is_captain_order": 1
      }
      await this._posService.getAllOrderData(onlineSendData).subscribe({
        next: async (res) => {
          // if (callFrom == 0) {
          //   this.checkApiStatus.find(x => x.name == "orderlistsync").value = 1;
          // }
          if (res.status === 1) {
            let orders = res.data;
            this.appComponent.stopDBChanges();
            this.ordersServiceDb.closeDbOnSync();
            await orders.forEach(async order => {
              order.isSync = 1;
              await this.enqueue(async () => {
                await this.insertUpdateOrder(order, 0, 0, 0, 0, 0, callFrom);
              });
            });
            this.appComponent.startDBChanges();

            resolve("")
          } else {
            resolve("")
          }
          localStorage.setItem('syncOrderCall', JSON.stringify(1))
        },
        error: (error) => {
          console.log(error);
        }
      });
      resolve('');
    })

  }


  //start sync online or miss order
  syncfromMobileOrdersFirstTime(callFrom = 0) {
    return new Promise(async resolve => {
      let onlineSendData = {
        // "terminal_id": localStorage.getItem("terminal_id"),
        "is_captain_order": 1
      }
      await this._posService.getAllOrderData(onlineSendData).subscribe({
        next: async (res) => {
          if (callFrom == 0) {
            this.checkApiStatus.find(x => x.name == "orderlistsync").value = 1;
          }
          if (res.status === 1) {
            let orders = res.data;
            this.appComponent.stopDBChanges();
            this.ordersServiceDb.closeDbOnSync();
            await orders.forEach(async order => {
              order.isSync = 1;
              await this.enqueue(async () => {
                await this.insertUpdateOrderFirstTime(order, 0, 0, 0, 0, 0, 1);
              });
            });
            this.appComponent.startDBChanges();

            resolve("")
          } else {
            resolve("")
          }
          localStorage.setItem('syncOrderCall', JSON.stringify(1))
        },
        error: (error) => {
          console.log(error);
        }
      });
      resolve('');
    })

  }

  isEmptyAddress(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  async enqueue(fn: () => Promise<any>) {
    this.queue.push(fn);
    if (!this.isProcessing) {
      await this.processQueue();
    }
  }

  private async processQueue() {
    this.isProcessing = true;
    while (this.queue.length > 0) {
      const fn = this.queue[0];
      try {
        const result = await fn();
      } catch (error) {
        console.error(error); // Handle the error as needed
      }
      this.queue.shift();
    }
    this.isProcessing = false;
  }

  async enqueueSyncOrder(fn: () => Promise<any>) {
    this.queueSyncOrder.push(fn);
    if (!this.isProcessingSyncOrder) {
      await this.processQueueSyncOrder();
    }
  }

  async enqueueSyncTables(fn: () => Promise<any>) {
    this.queueSyncTable.push(fn);
    if (!this.isProcessingSyncTable) {
      await this.processQueueSyncTable();
    }
  }

  async createSequentialOrderNo() {
    return 0;
    // let temp_store_order_sequence_id = JSON.parse(localStorage.getItem('store_order_sequence_id'))
    // let tempStoreId = JSON.parse(localStorage.getItem('store_id'))
    // let tempTerminal_id = JSON.parse(localStorage.getItem('terminal_sequence'));

    // let storeId = String(tempStoreId).padStart(4, '0');
    // let terminal_id = String(tempTerminal_id).padStart(2, '0');

    // let finalNumber = ''
    // if (temp_store_order_sequence_id == 0) {
    //   // new terminal
    //   finalNumber = storeId + terminal_id + 1;
    // } else {
    //   //old terminal
    //   let intoString = JSON.stringify(temp_store_order_sequence_id)
    //   let lastNumber = intoString.slice(7, intoString.length - 1)
    //   let inc = Number(lastNumber) + 1
    //   finalNumber = storeId + terminal_id + inc;
    // }
    // localStorage.setItem('store_order_sequence_id', JSON.stringify(finalNumber));
    // return finalNumber.toString()
  }

  createLocalTransactionNumber() {
    return 0;
    // var new_transaction_number = 1;
    // if (localStorage.getItem('local_transaction_no')) {
    //   new_transaction_number = Number(localStorage.getItem('local_transaction_no')) + 1;

    //   localStorage.setItem('local_transaction_no', new_transaction_number.toString());
    // }
    // return new_transaction_number;
  }

  async insertUpdateOrder(ordData, needToPrint = 0, updateByPid = 0, fromSyncFunction = 0, needToUpdateOrderNumber = 0, fromPage = 0, callFrom = 0) {
    //needToPrint  0 = no print,1 = recipt only,2 = recipt and kot ,3 = kot only
    //needToUpdateOrderNumber == For notification order like captain,qr etc.
    //fromPage  0 = nothing,1 = checkout page,2 = pos page,3=after sync
    var Alltables = await this.storeTablesServiceDb.use('GA_DATA', {})
    this.storePosBehavior = JSON.parse(localStorage.getItem('storePosBehavior'))
    return new Promise(async resolve => {
      // device type = 1 for ios and 2 for android and 0 for POS
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (ordData.store_order_sequence_id == 0) {
        ordData.store_order_sequence_id = await this.createSequentialOrderNo();
      }
      var new_transaction_number = Number(localStorage.getItem('local_transaction_no'));
      if (ordData.local_transaction_no == 0) {
        ordData.local_transaction_no = await this.createLocalTransactionNumber();
      } else {
        new_transaction_number = ordData.local_transaction_no;
      }
      ordData.new_transaction_number = new_transaction_number
      ordData.customer_name = ordData.order_customer.name + " " + ordData.order_customer.lname
      ordData.customer_phone = ordData.order_customer.phone

      let current_date = new Date(ordData.created_at_formated)
      var table = Alltables.find(x => x.id == ordData.table_id) //await this.storeTablesServiceDb.use('GA_DATA', { id: ordData.table_id })
      if (ordData.table_id != 0 && ordData.order_status != 1) {
        if (table) {
          if (ordData.order_status == 2) {
            table.booking_status = 0;
            table.isSync = 0;
            table.order_id = 0;
            table.order = {};
            table.table_occupied_time = 0;
            table.numberofguest = 0;
            table.merge_tables = '0';
            table.unmerge_tables = '0';
            table.isMerge = '0';
            // console.log('666');
            // console.log('callFrom--333--->',callFrom);
            // if(callFrom == 0) {
            this.storeTablesServiceDb.use('IU_DATA', [table]).then(async (res) => {
              let table = await this.storeTablesServiceDb.use('G_DATA', { "_id": res[0].id });
              if (this.internetStatus != "Offline" && table.isSync == 0) {
                await this.syncTables(table.pid)
              }
            });
            // }
          } else {
            if (this.internetStatus != "Offline" && fromPage == 2) {
              ordData.isSync = 2
            }
            ordData.table_name = table.title
            table.booking_status = 1
            table.order_id = ordData.id
            table.table_occupied_time = current_date
            // console.log('777');
            // console.log('callFrom--2222--->',callFrom);
            // if(callFrom == 0) {
            this.storeTablesServiceDb.use('IU_DATA', [table]).then(async (res) => {
              let table = await this.storeTablesServiceDb.use('G_DATA', { "_id": res[0].id });
              if (this.internetStatus != "Offline" && table.isSync == 0) {
                await this.syncTables(table.pid)
              }
            });
            // }

          }
        }


      } else if (ordData.table_id != 0 && ordData.order_status == 1) {
        if (table) {
          var getTableToUpdate = Alltables.filter(x => {
            if (x.isMerge == ordData.table_id || x.id == ordData.table_id) {
              return x;
            }
          })
          if (getTableToUpdate && getTableToUpdate.length > 0) {
            getTableToUpdate.map(x => {
              x.booking_status = 0;
              x.isSync = 0;
              x.order_id = 0;
              x.order = {};
              x.table_occupied_time = 0;
              x.numberofguest = 0;
              x.merge_tables = '0';
              x.unmerge_tables = '0';
              x.isMerge = '0';
            })
            // console.log('888');
            // console.log('callFrom--111--->',callFrom);
            // if(callFrom == 0) {
            this.storeTablesServiceDb.use('IU_DATA', getTableToUpdate).then(async (res) => {
              let table = await this.storeTablesServiceDb.use('G_DATA', { "_id": res[0].id });
              if (this.internetStatus != "Offline" && table.isSync == 0) {
                await this.syncTables(table.pid)
              }
            });
            // }

          }
        }
      }
      /*orderDetailServiceDb nn ADDED Below*/
      if (ordData.order_detail && ordData.order_detail.length > 0) {
        await ordData.order_detail.map((x) => {
          if (!x.local_insert_order_item_number) {
            var randomNumber = Math.floor(10000 + Math.random() * 90000);
            var timestamp = new Date().getTime();
            x.local_insert_order_item_number = Number(randomNumber + '' + timestamp)
          }
          if (parseInt(x.price_override_item_status) == 0) {
            x.cal_price = (parseFloat(x.pricelookup_item_price) * parseInt(x.pricelookup_qty)).toFixed(2);
            if (x.is_substitute == 0) {
              if (x.order_detail_add_add_ons && x.order_detail_add_add_ons.length > 0) {
                const addCal = x.order_detail_add_add_ons.reduce((acc, addon_element) =>
                  acc + parseFloat(addon_element.price) * parseInt(x.pricelookup_qty), 0);
                x.cal_price = (parseFloat(x.cal_price) + addCal).toFixed(2);
              }
            }
          } else {
            x.cal_price = (parseFloat(x.price_override_item_price) * parseInt(x.pricelookup_qty)).toFixed(2)
          }
          x.price = x.pricelookup_item_price;
        })
      }
      /*orderDetailServiceDb nn upper */
      await this.ordersServiceDb.use('IU_DATA', [ordData], updateByPid).then(async (res) => {
        let order = await this.ordersServiceDb.use('G_DATA', { "_id": res[0].id });
        // this.sendMessage(ordData)
        this.insertUpdateCustomerAndAddress(order.order_customer, order.customer_address).then(async () => {
          const default_customer = JSON.parse(JSON.stringify(await this.sharedDataService.getDefaultCustomer()));
          if (default_customer != undefined) {
            if (default_customer.phone == order.order_customer.phone) {
              await this.sharedDataService.changeDefaultCustomer()
            }
          }
        })
        if (callFrom == 0) {
          if (fromSyncFunction == 0 && this.internetStatus != "Offline") {
            this.syncOrderFromPosPage(order.pid)
          }
        }

        if (needToUpdateOrderNumber == 1 && false) {
          var order_number_update = {
            'store_id': order.store_id,
            'live_ref_id': order.store_order_id,
            'database_id': order.id,
            'order_number': order.store_order_sequence_id,
            'local_transaction_no': order.local_transaction_no,
          }
          this._posService.updateOrderNumber(order_number_update);
        }
        this.zone.runOutsideAngular(async () => {
          var storePosBehavior = JSON.parse(localStorage.getItem('storePosBehavior'))
          if (storePosBehavior != null) {
            if (storePosBehavior.is_disable_print_on_order_settlement == 0 && needToPrint != 0) {
              var needToprintKot = 0
              if (needToPrint == 1) {  // 1 = recipt only
                needToprintKot = 0 // 0 = recipt only
              } else if (needToPrint == 2) { // 2 = recipt and kot
                needToprintKot = 1 // 1 = recipt and kot
              } else if (needToPrint == 3) { //3 = kot only
                needToprintKot = 2 //2 = kot only
              }


              //order.order_detail = ordData.order_detail
              if (fromPage == 1) { //checkout page
                this.print.goToPrint(order, needToprintKot);
              } else if (fromPage == 2) { // pos page
                if (typeof ordData.id != 'string') {
                  //other wise only recipt call
                  if (needToprintKot == 1) {
                    needToprintKot = 0
                    this.print.goToPrint(order, needToprintKot);
                  }
                } else {
                  // send print for kot and recipt as required directly
                  this.print.goToPrint(order, needToprintKot);
                }
              } else if (fromPage == 3) { // after sync
                needToprintKot = 2
                this.print.goToPrint(order, needToprintKot, [], 1);
              }

            }
          }
        });
        resolve(order.pid);
      }).catch((err) => {
        console.log("error", err)
        resolve("");
      });
    });
  }

  async insertUpdateOrderFirstTime(ordData, needToPrint = 0, updateByPid = 0, fromSyncFunction = 0, needToUpdateOrderNumber = 0, fromPage = 0, callFrom = 0) {
    //needToPrint  0 = no print,1 = recipt only,2 = recipt and kot ,3 = kot only
    //needToUpdateOrderNumber == For notification order like captain,qr etc.
    //fromPage  0 = nothing,1 = checkout page,2 = pos page,3=after sync
    var Alltables = await this.storeTablesServiceDb.use('GA_DATA', {})
    this.storePosBehavior = JSON.parse(localStorage.getItem('storePosBehavior'))
    return new Promise(async resolve => {
      // device type = 1 for ios and 2 for android and 0 for POS
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (ordData.store_order_sequence_id == 0) {
        ordData.store_order_sequence_id = await this.createSequentialOrderNo();
      }
      var new_transaction_number = Number(localStorage.getItem('local_transaction_no'));
      if (ordData.local_transaction_no == 0) {
        ordData.local_transaction_no = await this.createLocalTransactionNumber();
      } else {
        new_transaction_number = ordData.local_transaction_no;
      }
      ordData.new_transaction_number = new_transaction_number
      ordData.customer_name = ordData.order_customer.name + " " + ordData.order_customer.lname
      ordData.customer_phone = ordData.order_customer.phone

      let current_date = new Date(ordData.created_at_formated)
      var table = Alltables.find(x => x.id == ordData.table_id) //await this.storeTablesServiceDb.use('GA_DATA', { id: ordData.table_id })
      if (ordData.table_id != 0 && ordData.order_status != 1) {
        if (table) {
          if (ordData.order_status == 2) {
            table.booking_status = 0;
            table.isSync = 0;
            table.order_id = 0;
            table.order = {};
            table.table_occupied_time = 0;
            table.numberofguest = 0;
            table.merge_tables = '0';
            table.unmerge_tables = '0';
            table.isMerge = '0';
            // console.log('666');
            // console.log('callFrom--333--->',callFrom);
            if (callFrom == 0) {
              this.storeTablesServiceDb.use('IU_DATA', [table]).then(async (res) => {
                let table = await this.storeTablesServiceDb.use('G_DATA', { "_id": res[0].id });
                if (this.internetStatus != "Offline" && table.isSync == 0) {
                  await this.syncTables(table.pid)
                }
              });
            }
          } else {
            if (this.internetStatus != "Offline" && fromPage == 2) {
              ordData.isSync = 2
            }
            ordData.table_name = table.title
            table.booking_status = 1
            table.order_id = ordData.id
            table.table_occupied_time = current_date
            // console.log('777');
            // console.log('callFrom--2222--->',callFrom);
            if (callFrom == 0) {
              this.storeTablesServiceDb.use('IU_DATA', [table]).then(async (res) => {
                let table = await this.storeTablesServiceDb.use('G_DATA', { "_id": res[0].id });
                if (this.internetStatus != "Offline" && table.isSync == 0) {
                  await this.syncTables(table.pid)
                }
              });
            }

          }
        }


      } else if (ordData.table_id != 0 && ordData.order_status == 1) {
        if (table) {
          var getTableToUpdate = Alltables.filter(x => {
            if (x.isMerge == ordData.table_id || x.id == ordData.table_id) {
              return x;
            }
          })
          if (getTableToUpdate && getTableToUpdate.length > 0) {
            getTableToUpdate.map(x => {
              x.booking_status = 0;
              x.isSync = 0;
              x.order_id = 0;
              x.order = {};
              x.table_occupied_time = 0;
              x.numberofguest = 0;
              x.merge_tables = '0';
              x.unmerge_tables = '0';
              x.isMerge = '0';
            })
            // console.log('888');
            // console.log('callFrom--111--->',callFrom);
            if (callFrom == 0) {
              this.storeTablesServiceDb.use('IU_DATA', getTableToUpdate).then(async (res) => {
                let table = await this.storeTablesServiceDb.use('G_DATA', { "_id": res[0].id });
                if (this.internetStatus != "Offline" && table.isSync == 0) {
                  await this.syncTables(table.pid)
                }
              });
            }
          }
        }
      }
      /*orderDetailServiceDb nn ADDED Below*/
      if (ordData.order_detail && ordData.order_detail.length > 0) {
        await ordData.order_detail.map((x) => {
          if (!x.local_insert_order_item_number) {
            var randomNumber = Math.floor(10000 + Math.random() * 90000);
            var timestamp = new Date().getTime();
            x.local_insert_order_item_number = Number(randomNumber + '' + timestamp)
          }
          if (parseInt(x.price_override_item_status) == 0) {
            x.cal_price = (parseFloat(x.pricelookup_item_price) * parseInt(x.pricelookup_qty)).toFixed(2);
            if (x.is_substitute == 0) {
              if (x.order_detail_add_add_ons && x.order_detail_add_add_ons.length > 0) {
                const addCal = x.order_detail_add_add_ons.reduce((acc, addon_element) =>
                  acc + parseFloat(addon_element.price) * parseInt(x.pricelookup_qty), 0);
                x.cal_price = (parseFloat(x.cal_price) + addCal).toFixed(2);
              }
            }
          } else {
            x.cal_price = (parseFloat(x.price_override_item_price) * parseInt(x.pricelookup_qty)).toFixed(2)
          }
          x.price = x.pricelookup_item_price;
        })
      }
      /*orderDetailServiceDb nn upper */
      await this.ordersServiceDb.use('IU_DATA', [ordData], updateByPid).then(async (res) => {
        let order = await this.ordersServiceDb.use('G_DATA', { "_id": res[0].id });
        // this.sendMessage(ordData)
        this.insertUpdateCustomerAndAddress(order.order_customer, order.customer_address).then(async () => {
          const default_customer = JSON.parse(JSON.stringify(await this.sharedDataService.getDefaultCustomer()));
          if (default_customer != undefined) {
            if (default_customer.phone == order.order_customer.phone) {
              await this.sharedDataService.changeDefaultCustomer()
            }
          }
        })
        if (callFrom == 0) {
          if (fromSyncFunction == 0 && this.internetStatus != "Offline") {
            this.syncOrderFromPosPage(order.pid)
          }
        }

        if (needToUpdateOrderNumber == 1 && false) {
          var order_number_update = {
            'store_id': order.store_id,
            'live_ref_id': order.store_order_id,
            'database_id': order.id,
            'order_number': order.store_order_sequence_id,
            'local_transaction_no': order.local_transaction_no,
          }
          this._posService.updateOrderNumber(order_number_update);
        }
        this.zone.runOutsideAngular(async () => {
          var storePosBehavior = JSON.parse(localStorage.getItem('storePosBehavior'))
          if (storePosBehavior != null) {
            if (storePosBehavior.is_disable_print_on_order_settlement == 0 && needToPrint != 0) {
              var needToprintKot = 0
              if (needToPrint == 1) {  // 1 = recipt only
                needToprintKot = 0 // 0 = recipt only
              } else if (needToPrint == 2) { // 2 = recipt and kot
                needToprintKot = 1 // 1 = recipt and kot
              } else if (needToPrint == 3) { //3 = kot only
                needToprintKot = 2 //2 = kot only
              }


              //order.order_detail = ordData.order_detail
              if (fromPage == 1) { //checkout page
                this.print.goToPrint(order, needToprintKot);
              } else if (fromPage == 2) { // pos page
                if (typeof ordData.id != 'string') {
                  //other wise only recipt call
                  if (needToprintKot == 1) {
                    needToprintKot = 0
                    this.print.goToPrint(order, needToprintKot);
                  }
                } else {
                  // send print for kot and recipt as required directly
                  this.print.goToPrint(order, needToprintKot);
                }
              } else if (fromPage == 3) { // after sync
                needToprintKot = 2
                this.print.goToPrint(order, needToprintKot, [], 1);
              }

            }
          }
        });
        resolve(order.pid);
      }).catch((err) => {
        console.log("error", err)
        resolve("");
      });
    });
  }

  private async processQueueSyncOrder() {
    this.isProcessingSyncOrder = true;
    while (this.queueSyncOrder.length > 0) {
      const fn = this.queueSyncOrder[0];
      try {
        const result = await fn();
      } catch (error) {
        console.error(error); // Handle the error as needed
      }
      this.queueSyncOrder.shift();
    }
    this.isProcessingSyncOrder = false;
  }

  private async processQueueSyncTable() {
    this.isProcessingSyncTable = true;
    while (this.queueSyncTable.length > 0) {
      const fn = this.queueSyncTable[0];
      try {
        const result = await fn();
      } catch (error) {
        console.error(error); // Handle the error as needed
      }
      this.queueSyncTable.shift();
    }
    this.isProcessingSyncTable = false;
  }

  async insertUpdateCustomerAndAddress(customer, customer_address: any) {
    return new Promise(async resolve => {
      //Sync mobile customer to local DB
      await this.customersServiceDb.use('IU_DATA', [customer]).then(async (res) => {

        if (Array.isArray(customer_address)) {
          await this.customerAddressServiceDb.use('IU_DATA', customer_address)
        } else {
          if (customer_address && customer_address.id && customer_address.society_name) {
            await this.customerAddressServiceDb.use('IU_DATA', [customer_address])
          }
        }

      })
      //END Sync mobile customer to local DB
      resolve("");
    })
  }

  async getOrdersFromDatabase(filter) {
    return new Promise(async resolve => {
      var getDataOf: any = {}
      if (filter.order_status || filter.order_status == 0) {
        getDataOf.order_status = filter.order_status
      }
      if (filter.order_status_array) {
        getDataOf.order_status = { "$in": filter.order_status_array }
      }
      if (filter.clerk_id) {
        getDataOf.created_by = filter.clerk_id
      }
      if (filter.transaction_no) {
        getDataOf.local_transaction_no = filter.transaction_no
      }
      if (filter.tender_profile_id) {
        getDataOf.tender_profile_id = filter.tender_profile_id
      }
      if (filter.tender_profile_id) {
        getDataOf.tender_profile_id = { "$in": filter.filter_tender_profile_id }
      }
      if (filter.order_no) {
        getDataOf.store_order_sequence_id = { "$in": filter.order_no }
      }
      if (filter.name) {
        getDataOf.customer_name = { "$regex": new RegExp(filter.name, 'i') }
      }
      if (filter.mobile_no) {
        getDataOf.customer_phone = { "$regex": new RegExp(filter.mobile_no, 'i') }
      }
      if (filter.pid) {
        getDataOf.pid = filter.pid
      }

      let store_opening_time = await this.generalServiceDb.use('GA_DATA', { "id": "store_opening_time" })
      if (store_opening_time && store_opening_time.length > 0) {
        let startDate = store_opening_time[0].value
        let newStartDate = Date.parse(startDate);
        getDataOf.created_at_formated = { "$gt": newStartDate }
      }
      var skip = (filter.page_no - 1) * 10
      var limit = 10
      var filterData = {
        "filter": getDataOf,
        "skip": skip,
        "limit": limit
      }
      let data = await this.ordersServiceDb.use('GA_DATA', filterData)
      let rowNumbers = await this.getOrderCounts(getDataOf);
      if (data && data.length > 0) {
        let datas = {
          orders: data,
          totals: rowNumbers
        }
        resolve(datas);
      } else {
        let datas = {
          orders: [],
          totals: rowNumbers
        }
        resolve(datas);
      }
    });
  }

  getOrderCounts(filter) {
    return new Promise(async resolve => {
      delete (filter.order_status) // Remove to get all record.
      delete (filter.pid) // Remove to get all record.
      let data = await this.ordersServiceDb.getAllForCount(filter)
      let saved = 0, comp = 0, cancel = 0, pending = 0, accepted = 0, processing = 0, delivery = 0, ready = 0
      if (data && data.length > 0) {
        await data.forEach(async element => {
          if (element.order_status == 0) {
            saved++;
          } else if (element.order_status == 1) {
            comp++;
          } else if (element.order_status == 2) {
            cancel++;
          } else if (element.order_status == 3) {
            pending++;
          } else if (element.order_status == 4) {
            accepted++;
          } else if (element.order_status == 6) {
            processing++;
          } else if (element.order_status == 5) {
            delivery++;
          } else if (element.order_status == 7) {
            ready++;
          }
        });
      }
      let rs = {
        saved: saved,
        comp: comp,
        cancel: cancel,
        pending: pending,
        accepted: accepted,
        processing: processing,
        ready: ready,
        delivery: delivery
      }
      resolve(rs)
    });
  }

  // async syncOrder(order_pid=0){

  //   await this.enqueueSyncOrder(async () => {
  //     var orders:any = []

  //     if(order_pid != 0){
  //       orders = await this.ordersServiceDb.use('GA_DATA', {filter:{pid:order_pid,isSync:0},skip:0,limit:1}) // only removed
  //     }else{
  //       let currentDate = new Date()
  //       currentDate.setSeconds(currentDate.getSeconds() - 10);
  //       let created_date = Date.parse(currentDate.toString());
  //       orders = await this.ordersServiceDb.use('GA_DATA', {filter:{created_at_formated:{$lte:created_date},isSync:0},skip:0,limit:5})
  //     }
  //     if(orders && orders.length > 0){
  //       await orders.forEach(async order => {
  //         if(order.store_order_id && order.store_order_id != 0 && order.order_status == 2){
  //           //order cancel API
  //           try {

  //             let senddata = {
  //               "terminal_id": order.terminal_id,
  //               "store_id": order.store_id,
  //               "clerk_id": order.created_by,
  //               "order_status":order.order_status,
  //               "order_id": order.id,
  //               "cancel_reason": order.cancellation_reason
  //             }
  //             await this._posService.ordercancel(senddata).subscribe({
  //               next: async (res) => {
  //                 if (res.status == 1) {
  //                   order.isSync = 1;
  //                   await this.enqueue(async () => {
  //                     await this.insertUpdateOrder(order, 0, 1, 1);
  //                   });
  //                 } else {
  //                   this.toastr.warning(res.message)
  //                   console.log("error:", res.status);
  //                 }
  //               },
  //               error: (error) => {
  //                 console.log(error);
  //               }
  //             });
  //           } catch (error) {
  //             console.log(error);
  //           }
  //         }else if(order.store_order_id && order.store_order_id != 0 && order.useOrderPlaceOrUpdate == 1){
  //          //order update API
  //           try {

  //             let senddata = {
  //               "order_id": order.id,
  //               "order_status": order.order_status,
  //               "terminal_id": localStorage.getItem('terminal_id'),
  //               "cancel_reason": ""
  //             }
  //             await this._posService.orderUpdate(senddata).subscribe({
  //               next: async (res) => {
  //                 if (res.status == 1) {
  //                   order.delivery_partner_order_details = res.data.delivery_partner_order_details
  //                   order.isSync = 1;
  //                   await this.enqueue(async () => {
  //                     await this.insertUpdateOrder(order, 0, 1, 1);
  //                   });
  //                 }else if (res.status == 2) {
  //                   //already completed
  //                   //order.delivery_partner_order_details = res.data.delivery_partner_order_details
  //                   order.isSync = 1;
  //                   await this.enqueue(async () => {
  //                     await this.insertUpdateOrder(order, 0, 1, 1);
  //                   });
  //                 } else {
  //                   this.toastr.warning(res.message)
  //                   console.log("error:", res.status);
  //                 }
  //               },
  //               error: (error) => {
  //                 console.log(error);
  //               }
  //             });
  //           } catch (error) {
  //             console.log(error);
  //           }

  //         }else{
  //           //order place API
  //           /*orderDetailServiceDb nn order.order_detail =  await this.orderDetailServiceDb.use('GA_DATA', {"order_pid": order.pid}) */ // Added here because in listing getting slow.

  //           try {
  //             order.database_id = order.id
  //             order.country_code = order.order_customer.country_code
  //             order.customer_address_data = order.customer_address_display
  //             order.order_detail.map((detail)=>{
  //               detail.id = 0
  //               detail.order_detail_database_id = 0
  //               detail.add_addons_detail = detail.order_detail_add_add_ons
  //               detail.remove_addons_detail = detail.order_detail_remove_add_ons
  //               if(detail.order_detail_tax && detail.order_detail_tax.length){
  //                 detail.taxes = detail.order_detail_tax.map((x)=>{                    
  //                   x.tax_cal = Number(x.tax_amount).toFixed(3)
  //                   return x;
  //                 })
  //               }else{
  //                 detail.taxes = []
  //               }
  //             })
  //             return await this._posService.placeOrder(order).subscribe({
  //               next: async (res) => {
  //                 if (res.status == 1) {
  //                   res.data.pid = order.pid;
  //                   res.data.isSync = 1;
  //                   var printkot =0
  //                   if(order.afterSyncNeedToPrintKot == 1){
  //                     printkot = 3
  //                   }
  //                   await this.enqueue(async () => {
  //                     await this.insertUpdateOrder(res.data,printkot,1,1,0,3);
  //                   });
  //                 } else {
  //                   console.log("error:", res.status);
  //                 }
  //               },
  //               error: (error) => {
  //                 console.log(error);
  //               }
  //             });
  //           } catch (error) {
  //             console.log(error);
  //           }
  //         }
  //         return 0;
  //       });
  //     }
  //     return 1;
  //   });
  // }

  async syncOrder(order_pid = 0) {
    await this.enqueueSyncOrder(async () => {
      this.zone.runOutsideAngular(async () => {
        try {
          const orders = order_pid !== 0
            ? await this.ordersServiceDb.use('GA_DATA', { filter: { pid: order_pid, isSync: 0 }, skip: 0, limit: 1 })
            : await this.ordersServiceDb.use('GA_DATA', { filter: { created_at_formated: { $lte: Date.now() - 10000 }, isSync: 0 }, skip: 0, limit: 5 });

          if (!orders || orders.length === 0) {
            return;
          }
          // console.log('orders',orders)
          // console.log('orders[orders.length-1]', orders[orders.length - 1])
          let last_order_local_transaction_no = orders[orders.length - 1]['local_transaction_no'] ?? 0;
          for (const order of orders) {
            order.is_captain_order = 1;
            var currentUser = JSON.parse(localStorage.getItem('currentUser'));
            order.captain_id = currentUser.id
            if (order.store_order_id && order.store_order_id != 0 && order.order_status === 2) {
              this.handleOrderCancel(order);
            } else if (order.store_order_id && order.store_order_id != 0 && order.useOrderPlaceOrUpdate === 1) {
              this.handleOrderUpdate(order, last_order_local_transaction_no);
            } else {
              this.handleOrderPlace(order, last_order_local_transaction_no);
            }
          }
        } catch (error) {
          console.error('Error in syncOrder:', error);
        }
      });
    });
  }

  async handleOrderCancel(order) {
    try {
      const senddata = {
        terminal_id: order.terminal_id,
        store_id: order.store_id,
        clerk_id: order.created_by,
        order_status: order.order_status,
        order_id: order.id,
        cancel_reason: order.cancellation_reason,
      };

      this._posService.ordercancel(senddata).subscribe({
        next: async (res) => {
          if (res.status === 1) {
            order.isSync = 1;
            await this.enqueue(() => this.insertUpdateOrder(order, 0, 1, 1, 0, 0, 1));
          } else {
            this.toastr.warning(res.message);
            console.error('Error:', res.status);
          }
        },
        error: (error) => {
          console.log(error);
        }
      });

    } catch (error) {
      console.error(error);
    }
  }

  async handleOrderUpdate(order, last_order_local_transaction_no = 0) {
    this.isSyncOrder = false;
    try {
      const senddata = {
        order_id: order.id,
        order_status: order.order_status,
        terminal_id: localStorage.getItem('terminal_id'),
        cancel_reason: '',
      };

      await this._posService.orderUpdate(senddata).subscribe({
        next: async (res) => {
          if (res.status === 1 || res.status === 2) {
            order.isSync = 1;
            await this.enqueue(() => this.insertUpdateOrder(order, 0, 1, 1, 0, 0, 1));
            if (order.local_transaction_no == last_order_local_transaction_no) {
              this.isSyncOrder = true;
            }
          } else {
            this.toastr.warning(res.message);
            console.error('Error:', res.status);
          }
        },
        error: (error) => {
          console.log(error);
          this.isSyncOrder = true;
        }
      });
    } catch (error) {
      console.error(error);
      this.isSyncOrder = true;
    }
  }

  async handleOrderPlace(order, last_order_local_transaction_no = 0) {
    this.isSyncOrder = false;
    try {
      order.database_id = order.id;
      order.country_code = order.order_customer.country_code;
      order.customer_address_data = order.customer_address_display;

      order.order_detail.forEach((detail) => {
        detail.id = 0;
        detail.order_detail_database_id = 0;
        detail.add_addons_detail = detail.order_detail_add_add_ons;
        detail.remove_addons_detail = detail.order_detail_remove_add_ons;

        if (detail.order_detail_tax && detail.order_detail_tax.length) {
          detail.taxes = detail.order_detail_tax.map((x) => ({
            ...x,
            tax_cal: Number(x.tax_amount).toFixed(3),
          }));
        } else {
          detail.taxes = [];
        }
      });

      await this._posService.placeOrder(order).subscribe({
        next: async (res) => {
          if (res.status === 1) {
            res.data.pid = order.pid;
            res.data.isSync = 1;
            const printkot = order.afterSyncNeedToPrintKot === 1 ? 3 : 0;
            await this.enqueue(() => this.insertUpdateOrder(res.data, printkot, 1, 1, 0, 3, 1));
            if (order.local_transaction_no == last_order_local_transaction_no) {
              this.isSyncOrder = true;
            }
          } else {
            console.error('Error:', res.status);
          }
        },
        error: (error) => {
          console.log(error);
          this.isSyncOrder = true;
        }
      });




    } catch (error) {
      console.error(error);
      this.isSyncOrder = true;
    }
  }

  // async syncTables() {
  //   await this.enqueueSyncTables(async () => {
  //     var tables: any = []
  //     //let currentDate = new Date()
  //     //currentDate.setSeconds(currentDate.getSeconds() - 10);
  //    // let created_date = Date.parse(currentDate.toString());
  //     tables = await this.storeTablesServiceDb.use('GA_DATA', { isSync: 0 })
  //     if (tables && tables.length > 0) {
  //       await tables.slice(0, 3).forEach(async table => {
  //         try {
  //           table.table_id = table.id
  //           table.is_captain_order = 1;
  //           if (typeof table.order_id != "string") {
  //             await this._posService.addTable(table).subscribe({
  //               next: async (res) => {
  //                 if (res.status == 1) {
  //                   table.isSync = 1;
  //                   this.storeTablesServiceDb.use('IU_DATA', [table])
  //                 } else {
  //                   table.isSync = 1;
  //                   this.storeTablesServiceDb.use('IU_DATA', [table])
  //                   this.toastr.warning(res.message)
  //                   console.log("error:", res.status);
  //                 }
  //               },
  //               error: (error) => {
  //                 console.log(error);
  //               }
  //             });
  //           }
  //         } catch (error) {
  //           console.log(error);
  //         }

  //         return 0;
  //       });
  //     }
  //   });
  // }

  async syncTables(table_pid = 0) {
    await this.enqueueSyncTables(async () => {

      const tables = table_pid !== 0
        ? await this.storeTablesServiceDb.use('GA_DATA', { filter: { pid: table_pid, isSync: 0 }, skip: 0, limit: 1 })
        : await this.storeTablesServiceDb.use('GA_DATA', { filter: { created_at_formated: { $lte: Date.now() - 10000 }, isSync: 0 }, skip: 0, limit: 5 });

      if (tables && tables.length > 0) {
        await tables.forEach(async table => {
          try {
            table.table_id = table.id;
            table.terminal_id = table.terminal_id;
            table.is_captain_order = 1;
            if (typeof table.order_id != "string") {
              await this._posService.addTable(table).subscribe({
                next: async (res) => {
                  if (res.status == 1) {
                    table.isSync = 1;
                    this.storeTablesServiceDb.use('IU_DATA', [table])
                  } else {
                    table.isSync = 1;
                    this.storeTablesServiceDb.use('IU_DATA', [table])
                    this.toastr.warning(res.message)
                    console.log("error:", res.status);
                  }
                },
                error: (error) => {
                  console.log(error);
                }
              });
            }
          } catch (error) {
            console.log(error);
          }

          return 0;
        });
      }
    });
  }

  async checkEndofDay() {
    let check = JSON.parse(localStorage.getItem('endOfDay'))
    let startDate = (await this.generalServiceDb.use('GA_DATA', { "id": "store_opening_time" }))[0]?.value

    var start_date;
    if (startDate) {
      start_date = new Date(startDate);
    } else {
      start_date = new Date();
    }
    start_date.setHours(0, 0, 0, 0);
    let currentDate = new Date()

    currentDate.setHours(0, 0, 0, 0);
    if (!check && check != null) {
      if (currentDate > start_date) {
        var now = this.datePipe.transform(new Date(), 'HH:mm:ss');
        if (now > "07:00:00") {
          this.toastr.warning(this.translate.instant("Please do end of day before start new Day"))
          this._router.navigate(['/end-of-day'])
        }
      }
    }
  }

  translateData(primary, secondary) {
    var store_detail = JSON.parse(localStorage.getItem('store_detail'));
    if (store_detail.default_languages.pos == 0) {
      //primary
      return primary

      //return secondary+"arr";
    } else {
      //secondary
      if (secondary == "" || secondary == undefined || secondary == null) {
        return primary
      } else {
        return secondary
      }
      //return secondary
    }
  }

  // pushToArray ( arr, obj ) {

  //   var existingIds = arr.map((obj) => obj.pid);

  //     if (! existingIds.includes(obj.pid)) {
  //       arr.push(obj);
  //     } else {
  //       arr.forEach((element, index) => {
  //         if (element.pid === obj.pid) {
  //           arr[index] = obj;
  //         };
  //       });
  //     };
  //     //console.log('arr22',arr);
  //     return arr;
  // };

  pushToArray(arr, obj) {
    const existingIndex = arr.findIndex(element => element.pid === obj.pid);
    if (existingIndex === -1) {
      arr.push(obj);
    } else {
      arr[existingIndex] = obj;
    }
    return arr;
  }

  // async syncBGOrder() {
  //   this.appComponent.spinnerActivate()
  //   await this.syncfromMobileOrders(1)
  //   await this.storeTables(1)
  //   await this.sharedDataService.getOrdersListArray(1)
  //   await this.sharedDataService.getTableListArray(1)
  //   this.appComponent.spinnerDeactivate()
  // }

  async syncBGOrder() {
    this.appComponent.spinnerActivate()
    try {
      this._router.navigate(['dashboard']);
      this.isExecutingInterval = false;
      this.sharedDataService.removeSharedDataFromAppIsOpenFromBG();
      await this.ordersServiceDb.use('DROP_ADD', [])
      await this.storeTablesServiceDb.use('DROP_ADD', [])
      await this.storeTables1(1);
      await this.syncfromMobileOrdersWhenAppKillOrMinimize(1);
      console.log("Websql data added..!");
      this.appComponent.spinnerDeactivate()
      this.isExecutingInterval = true;
      return 1;
    } catch (error) {
      console.log(error);
      this.appComponent.spinnerDeactivate()
      this.isExecutingInterval = true;
      return 0;
    }
  }

  syncfromMobileOrdersWhenAppKillOrMinimize(callFrom = 0) {
    return new Promise(async resolve => {
      let onlineSendData = {
        // "terminal_id": localStorage.getItem("terminal_id"),
        "is_captain_order": 1
      }
      await this._posService.getAllOrderData(onlineSendData).subscribe({
        next: async (res) => {
          if (callFrom == 0) {
            this.checkApiStatus.find(x => x.name == "orderlistsync").value = 1;
          }
          if (res.status === 1) {
            let orders = res.data;
            this.appComponent.stopDBChanges();
            this.ordersServiceDb.closeDbOnSync();
            await orders.forEach(async order => {
              order.isSync = 1;
              await this.enqueue(async () => {
                await this.insertUpdateOrderwhenAppKillOrMinimize(order, 0, 0, 0, 0, 0, callFrom);
              });
            });
            this.appComponent.startDBChanges();
            resolve("")
          } else {
            resolve("")
          }
          localStorage.setItem('syncOrderCall', JSON.stringify(1))
        },
        error: (error) => {
          console.log(error);
        }
      });
      resolve('');
    })

  }

  async insertUpdateOrderwhenAppKillOrMinimize(ordData, needToPrint = 0, updateByPid = 0, fromSyncFunction = 0, needToUpdateOrderNumber = 0, fromPage = 0, callFrom = 0) {
    //needToPrint  0 = no print,1 = recipt only,2 = recipt and kot ,3 = kot only
    //needToUpdateOrderNumber == For notification order like captain,qr etc.
    //fromPage  0 = nothing,1 = checkout page,2 = pos page,3=after sync
    var Alltables = await this.storeTablesServiceDb.use('GA_DATA', {})
    this.storePosBehavior = JSON.parse(localStorage.getItem('storePosBehavior'))
    return new Promise(async resolve => {
      // device type = 1 for ios and 2 for android and 0 for POS
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (ordData.store_order_sequence_id == 0) {
        ordData.store_order_sequence_id = await this.createSequentialOrderNo();
      }
      var new_transaction_number = Number(localStorage.getItem('local_transaction_no'));
      if (ordData.local_transaction_no == 0) {
        ordData.local_transaction_no = await this.createLocalTransactionNumber();
      } else {
        new_transaction_number = ordData.local_transaction_no;
      }
      ordData.new_transaction_number = new_transaction_number
      ordData.customer_name = ordData.order_customer.name + " " + ordData.order_customer.lname
      ordData.customer_phone = ordData.order_customer.phone

      let current_date = new Date(ordData.created_at_formated)
      var table = Alltables.find(x => x.id == ordData.table_id) //await this.storeTablesServiceDb.use('GA_DATA', { id: ordData.table_id })
      if (ordData.table_id != 0 && ordData.order_status != 1) {
        if (table) {
          if (ordData.order_status == 2) {
            table.booking_status = 0;
            table.isSync = 0;
            table.order_id = 0;
            table.order = {};
            table.table_occupied_time = 0;
            table.numberofguest = 0;
            table.merge_tables = '0';
            table.unmerge_tables = '0';
            table.isMerge = '0';
            if (callFrom == 0) {
              // console.log('111');
              this.storeTablesServiceDb.use('IU_DATA', [table]).then(async (res) => {
                let table = await this.storeTablesServiceDb.use('G_DATA', { "_id": res[0].id });
                if (this.internetStatus != "Offline" && table.isSync == 0) {
                  await this.syncTables(table.pid)
                }
              });
            }
          } else {
            ordData.table_name = table.title
            table.booking_status = 1
            table.order_id = ordData.id
            table.table_occupied_time = current_date
            if (callFrom == 0) {
              // console.log('222');
              this.storeTablesServiceDb.use('IU_DATA', [table]).then(async (res) => {
                let table = await this.storeTablesServiceDb.use('G_DATA', { "_id": res[0].id });
                if (this.internetStatus != "Offline" && table.isSync == 0) {
                  await this.syncTables(table.pid)
                }
              });
            }
          }
        }


      } else if (ordData.table_id != 0 && ordData.order_status == 1) {
        if (table) {
          var getTableToUpdate = Alltables.filter(x => {
            if (x.isMerge == ordData.table_id || x.id == ordData.table_id) {
              return x;
            }
          })
          if (getTableToUpdate && getTableToUpdate.length > 0) {
            getTableToUpdate.map(x => {
              x.booking_status = 0;
              x.isSync = 0;
              x.order_id = 0;
              x.order = {};
              x.table_occupied_time = 0;
              x.numberofguest = 0;
              x.merge_tables = '0';
              x.unmerge_tables = '0';
              x.isMerge = '0';
            })
            if (callFrom == 0) {
              // console.log('callFrom', callFrom);

              // console.log('333');
              this.storeTablesServiceDb.use('IU_DATA', getTableToUpdate).then(async (res) => {
                let table = await this.storeTablesServiceDb.use('G_DATA', { "_id": res[0].id });
                if (this.internetStatus != "Offline" && table.isSync == 0) {
                  await this.syncTables(table.pid)
                }
              });
            }
          }
        }
      }
      /*orderDetailServiceDb nn ADDED Below*/
      if (ordData.order_detail && ordData.order_detail.length > 0) {
        await ordData.order_detail.map((x) => {
          if (!x.local_insert_order_item_number) {
            var randomNumber = Math.floor(10000 + Math.random() * 90000);
            var timestamp = new Date().getTime();
            x.local_insert_order_item_number = Number(randomNumber + '' + timestamp)
          }
          if (parseInt(x.price_override_item_status) == 0) {
            x.cal_price = (parseFloat(x.pricelookup_item_price) * parseInt(x.pricelookup_qty)).toFixed(2);
            if (x.is_substitute == 0) {
              if (x.order_detail_add_add_ons && x.order_detail_add_add_ons.length > 0) {
                const addCal = x.order_detail_add_add_ons.reduce((acc, addon_element) =>
                  acc + parseFloat(addon_element.price) * parseInt(x.pricelookup_qty), 0);
                x.cal_price = (parseFloat(x.cal_price) + addCal).toFixed(2);
              }
            }
          } else {
            x.cal_price = (parseFloat(x.price_override_item_price) * parseInt(x.pricelookup_qty)).toFixed(2)
          }
          x.price = x.pricelookup_item_price;
        })
      }
      /*orderDetailServiceDb nn upper */
      await this.ordersServiceDb.use('IU_DATA', [ordData], updateByPid).then(async (res) => {
        let order = await this.ordersServiceDb.use('G_DATA', { "_id": res[0].id });
        // this.sendMessage(ordData)
        this.insertUpdateCustomerAndAddress(order.order_customer, order.customer_address).then(async () => {
          const default_customer = JSON.parse(JSON.stringify(await this.sharedDataService.getDefaultCustomer()));
          if (default_customer != undefined) {
            if (default_customer.phone == order.order_customer.phone) {
              await this.sharedDataService.changeDefaultCustomer()
            }
          }
        })

        // if (fromSyncFunction == 0 && this.internetStatus != "Offline") {
        //   this.syncOrder(order.pid)
        // }
        if (needToUpdateOrderNumber == 1 && false) {
          var order_number_update = {
            'store_id': order.store_id,
            'live_ref_id': order.store_order_id,
            'database_id': order.id,
            'order_number': order.store_order_sequence_id,
            'local_transaction_no': order.local_transaction_no,
          }
          this._posService.updateOrderNumber(order_number_update);
        }
        this.zone.runOutsideAngular(async () => {
          var storePosBehavior = JSON.parse(localStorage.getItem('storePosBehavior'))
          if (storePosBehavior != null) {
            if (storePosBehavior.is_disable_print_on_order_settlement == 0 && needToPrint != 0) {
              var needToprintKot = 0
              if (needToPrint == 1) {  // 1 = recipt only
                needToprintKot = 0 // 0 = recipt only
              } else if (needToPrint == 2) { // 2 = recipt and kot
                needToprintKot = 1 // 1 = recipt and kot
              } else if (needToPrint == 3) { //3 = kot only
                needToprintKot = 2 //2 = kot only
              }


              //order.order_detail = ordData.order_detail
              if (fromPage == 1) { //checkout page
                this.print.goToPrint(order, needToprintKot);
              } else if (fromPage == 2) { // pos page
                if (typeof ordData.id != 'string') {
                  //other wise only recipt call
                  if (needToprintKot == 1) {
                    needToprintKot = 0
                    this.print.goToPrint(order, needToprintKot);
                  }
                } else {
                  // send print for kot and recipt as required directly
                  this.print.goToPrint(order, needToprintKot);
                }
              } else if (fromPage == 3) { // after sync
                needToprintKot = 2
                this.print.goToPrint(order, needToprintKot, [], 1);
              }

            }
          }
        });
        resolve(order.pid);
      }).catch((err) => {
        console.log("error", err)
        resolve("");
      });
    });
  }

  async syncOrderFromPosPage(order_pid = 0) {
    await this.enqueueSyncOrder(async () => {
      this.zone.runOutsideAngular(async () => {
        try {
          const orders = order_pid !== 0
            ? await this.ordersServiceDb.use('GA_DATA', { filter: { pid: order_pid, isSync: 2 }, skip: 0, limit: 1 })
            : await this.ordersServiceDb.use('GA_DATA', { filter: { created_at_formated: { $lte: Date.now() - 10000 }, isSync: 2 }, skip: 0, limit: 5 });

          if (!orders || orders.length === 0) {
            return;
          }
          // console.log('orders',orders)
          // console.log('orders[orders.length-1]', orders[orders.length - 1])
          let last_order_local_transaction_no = orders[orders.length - 1]['local_transaction_no'] ?? 0;
          for (const order of orders) {
            order.is_captain_order = 1;
            var currentUser = JSON.parse(localStorage.getItem('currentUser'));
            order.captain_id = currentUser.id
            if (order.store_order_id && order.store_order_id != 0 && order.order_status === 2) {
              this.handleOrderCancel(order);
            } else if (order.store_order_id && order.store_order_id != 0 && order.useOrderPlaceOrUpdate === 1) {
              this.handleOrderUpdate(order, last_order_local_transaction_no);
            } else {
              this.handleOrderPlace(order, last_order_local_transaction_no);
            }
          }

        } catch (error) {
          console.error('Error in syncOrder:', error);
        }
      });
    });
  }

  storeTables1(callFrom = 0) {
    return new Promise(resolve => {
      try {
        this.http.post(appConfig.apiUrl + '/table-list', '').subscribe({
          next: async (res) => {
            if (callFrom == 0) {
              this.checkApiStatus.find(x => x.name == "storetables").value = 1;
            }
            if (res['status'] == 1) {
              if (res['data'].length > 0) {
                res['data'].map(x => x.isSync = 1)
                this.appComponent.stopDBChanges();
                this.ordersServiceDb.closeDbOnSync();
                await this.storeTablesServiceDb.use('IU_DATA', res['data']).then(async (res) => {
                  let table = await this.storeTablesServiceDb.use('G_DATA', { "_id": res[0].id });
                  if (this.internetStatus != "Offline" && table.isSync == 0) {
                    this.syncTables(table.pid)
                  }
                });
                this.appComponent.startDBChanges();
              }
              resolve('')
            } else {
              resolve('')
            }
          }, error: (error) => {
            console.log(error);
            resolve('')
          }
        });
      } catch (error) {
        console.log(error);
        resolve('')
      }
    });
  }

}
