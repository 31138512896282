import { Injectable } from '@angular/core';
import { PluMenuItemService } from './plu-menu-item.service';
import { TenderProfilesStoreService } from './tender-profiles-store.service';
import { PluCategoryListService } from './plu-category-list.service';
import { CustomersService } from './customers.service';
import { OrderDetailService } from './order-detail.service';
import { OrdersService } from './orders.service';
import { appConfig } from '../app.config';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { StoreTablesService } from './store-tables.service';
import { TenderTypesService } from './tender-types.service';
import { ReasonsService } from './reasons.service';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private pluMenuItemServiceDbArray: any[] = [];
  private tenderProfilesStoreServiceArray: any[] = [];
  private pluCategoryListServiceArray: any[] = [];
  private ordersMain: any[] = [];
  private reasonsMain: any[] = [];
  private tablesMain: any[] = [];
  private tenderTypeList: any[] = [];
  private defaultCustomer: any = {};
  private store_detail = JSON.parse(localStorage.getItem('store_detail'));

  private dataSubject: BehaviorSubject<any>;
  public data$: Observable<any>;

  private dataSubjectTable: BehaviorSubject<any>;
  public dataTable$: Observable<any>;

  constructor(public pluMenuItemServiceDb: PluMenuItemService, public tenderProfilesStoreServiceDb: TenderProfilesStoreService, public pluCategoryListServiceDb: PluCategoryListService, public customersServiceDb: CustomersService, public orderDetailServiceDb: OrderDetailService, public ordersServiceDb: OrdersService, public reasonsServiceDb: ReasonsService, public storeTablesServiceDb: StoreTablesService, public TenderTypesServiceDb: TenderTypesService) {

    this.dataSubject = new BehaviorSubject<any>(null);
    this.data$ = this.dataSubject.asObservable();

    this.dataSubjectTable = new BehaviorSubject<any>(null);
    this.dataTable$ = this.dataSubjectTable.asObservable();
  }



  async setAllData() {
    await this.getPluMenuItemListArray();
    await this.getTenderProfilesListArray();
    await this.getPluCategoryListArray();
    await this.getTenterTypesListArray();
    await this.getDefaultCustomer();
    await this.getOrdersListArray();
  }

  async orderDbChanges(data) {
    if (this.ordersMain.length > 0) {
      const tenderProfilesStoreService = await this.getTenderProfilesListArray();
      var newDataToAdd = await this.ordersServiceDb.manageData(data.doc, tenderProfilesStoreService)
      const existingIndex = this.ordersMain.findIndex(element => element.pid === newDataToAdd.pid);
      if (existingIndex === -1) {
        this.ordersMain.push(newDataToAdd);
      } else {
        this.ordersMain[existingIndex] = newDataToAdd;
      }
      this.dataSubject.next({ 'index': existingIndex, data: newDataToAdd });
    } else {
      this.ordersMain = await this.getOrdersListArray();
      this.dataSubject.next({ 'index': -1, data: newDataToAdd });
    }
  }

  async reasonsDbChanges(data) {
    if (this.reasonsMain.length > 0) {
      const existingIndex = this.reasonsMain.findIndex(element => element.pid === data.doc.pid);
      if (existingIndex === -1) {
        this.reasonsMain.push(data.doc);
      } else {
        this.reasonsMain[existingIndex] = data.doc;
      }
    } else {
      this.reasonsMain = await this.getReasonsListArray();
    }
  }

  async tablesDbChanges(data) {
    this.tablesMain = []
    if (this.tablesMain.length > 0 && data && data.doc) {
      const existingIndex = this.tablesMain.findIndex(x => x && x.id == data.doc.id)
      if (existingIndex === -1) {
        this.tablesMain.push(data.doc);
      } else {
        this.tablesMain[existingIndex] = data.doc;
      }
      this.dataSubjectTable.next({ 'index': existingIndex, data: data.doc });
    } else {
      this.tablesMain = await this.getTableListArray();
      this.dataSubjectTable.next({ 'index': -1, data: data.doc });
    }
  }

  async getPluMenuItemListArray() {
    if (this.pluMenuItemServiceDbArray && this.pluMenuItemServiceDbArray.length > 0) {
      return JSON.parse(JSON.stringify(this.pluMenuItemServiceDbArray));
    } else {
      this.pluMenuItemServiceDbArray = await this.pluMenuItemServiceDb.use('GA_DATA', {});
      await this.pluMenuItemServiceDbArray.map(x => {
        x.isVarationsItem = 0;
        if (x.parent_id == 0 && ((this.pluMenuItemServiceDbArray.filter(y => y.parent_id == x.id)).length > 0)) {
          x.isVarationsItem = 1;
        }
        return x
      })
      return this.pluMenuItemServiceDbArray;
    }
  }

  async getTenderProfilesListArray() {
    //return this.tenderProfilesStoreServiceArray = JSON.parse(localStorage.getItem('tenderProfilesStoreServiceDb'));

    if (this.tenderProfilesStoreServiceArray && this.tenderProfilesStoreServiceArray.length > 0) {
      return this.tenderProfilesStoreServiceArray;
    } else {
      return this.tenderProfilesStoreServiceArray = await this.tenderProfilesStoreServiceDb.use('GA_DATA', {});
    }


    // if (appConfig.buildCreateFor == "ios") {
    //   // not manage in ios so record fetch in database
    //   return this.tenderProfilesStoreServiceArray = await this.tenderProfilesStoreServiceDb.use('GA_DATA', {});
    // } else {
    //   if (this.tenderProfilesStoreServiceArray && this.tenderProfilesStoreServiceArray.length > 0) {
    //     return this.tenderProfilesStoreServiceArray;
    //   } else {
    //     return this.tenderProfilesStoreServiceArray = await this.tenderProfilesStoreServiceDb.use('GA_DATA', {});
    //   }
    // }
  }


  async getPluCategoryListArray() {
    if (this.pluCategoryListServiceArray && this.pluCategoryListServiceArray.length > 0) {
      return this.pluCategoryListServiceArray;
    } else {
      return this.pluCategoryListServiceArray = await this.pluCategoryListServiceDb.use('GA_DATA', {});
    }
  }

  async getOrdersListArray(forceGetData = 0) {
    if (this.ordersMain && this.ordersMain.length > 0 && forceGetData == 0) {
      return this.ordersMain;
    } else {
      return this.ordersMain = await this.ordersServiceDb.use('GA_DATA', {}) ?? [];
    }
  }

  async getOrdersListArrayForTables() {
    return this.ordersMain = await this.ordersServiceDb.use('GA_DATA', {}) ?? [];
  }


  async getReasonsListArray() {
    if (this.reasonsMain && this.reasonsMain.length > 0) {
      return this.reasonsMain;
    } else {
      return this.reasonsMain = await this.reasonsServiceDb.use('GA_DATA', {}) ?? [];
    }
  }

  async getTableListArray(forceGetData = 0) {
    if (this.tablesMain && this.tablesMain.length > 0 && forceGetData == 0) {
      return this.tablesMain;
    } else {
      return this.tablesMain = await this.storeTablesServiceDb.use('GA_DATA', { filter: { status: 1 } }) ?? [];
    }
  }
  async getTenterTypesListArray() {
    if (this.tenderTypeList && this.tenderTypeList.length > 0) {
      return this.tenderTypeList;
    } else {
      return this.tenderTypeList = await this.TenderTypesServiceDb.use('GA_DATA', {}) ?? [];
    }
  }

  async getDefaultCustomer() {
    if (!this.isObjectEmpty(this.defaultCustomer ?? {})) {
      return this.defaultCustomer
    } else {
      return this.defaultCustomer = (await this.customersServiceDb.use('GA_DATA', { id: this.store_detail?.default_customer?.id }))[0]
    }
  }

  isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  removeSharedData() {
    this.pluMenuItemServiceDbArray = [];
    this.tenderProfilesStoreServiceArray = [];
    this.pluCategoryListServiceArray = [];
    this.ordersMain = [];
    this.tablesMain = [];
    this.tenderTypeList = [];
    this.defaultCustomer = {};
    this.store_detail = JSON.parse(localStorage.getItem('store_detail'));
  }

  removeSharedDataFromAppIsOpenFromBG() {
    this.ordersMain = [];
    this.tablesMain = [];
  }

  async changeDefaultCustomer() {
    return this.defaultCustomer = (await this.customersServiceDb.use('GA_DATA', { id: this.store_detail?.default_customer?.id }))[0]
  }



}