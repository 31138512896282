import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TerminalGuard } from './guards/terminal.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./system/login/login.module').then(m => m.LoginPageModule),
  },
 
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'pos',
    loadChildren: () => import('./pages/pos/pos.module').then( m => m.PosPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'pos-dashboard',
    loadChildren: () => import('./pages/tables/tables.module').then( m => m.TablesPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'tables',
    loadChildren: () => import('./pages/tables/tables.module').then( m => m.TablesPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'setting',
    loadChildren: () => import('./pages/setting/setting.module').then( m => m.SettingPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then( m => m.OrdersPageModule),
    canActivate: [TerminalGuard]
  },
  

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
