import { Injectable, Injector } from '@angular/core';
import PouchDB from 'pouchdb';
import pouchdbFind from 'pouchdb-find';
import { Observable, BehaviorSubject } from 'rxjs';
import { SharedDataService } from './shared-data.service';

PouchDB.plugin(pouchdbFind);


@Injectable({
  providedIn: 'root'
})
export class OrderDetailService {
  private orderDetailServiceListDB: any;
  private sequenceDb: any;
  private sequenceDocId = 'orderDetailServiceList_sequence';
  // private dataSubject: BehaviorSubject<any>;
  // public data$: Observable<any>;

  constructor(private injector: Injector) {
    this.orderDetailServiceListDB = new PouchDB('orderDetailServiceList')
    this.orderDetailServiceListDB.createIndex({
      index: {
        fields: ['pid', 'id','order_pid']
      }
    })
    this.sequenceDb = new PouchDB('mydb_sequence');

    // this.dataSubject = new BehaviorSubject<any>(null);
    // this.data$ = this.dataSubject.asObservable();

    // this.orderDetailServiceListDB.changes({
    //   live: true,
    //   since: 'now',
    //   include_docs: true
    // }).on('change', async (change) => {
    //   this.dataSubject.next(change);
    // }).on('error', (error) => {
    //   console.error('Error:', error);
    // });
  }

  public async use(fc, data) {
    if (fc == "G_DATA") {
      return this.getById(data._id)
    } else if (fc == "GA_DATA") {
      return await this.getAll(data)
    } else if (fc == "IU_DATA") {
      return await this.bulkAddOrUpdate(this.orderDetailServiceListDB, data, "id");
    } else if (fc == "D_DATA") {
      return this.delete(data.id)
    } else if (fc == "DROP_ADD") {
      return await this.dropAdd(data);
    } else if (fc == "D_DATA_MAIN") {
      return await this.deleteDirect(data);
    }  
      
  }

  // async closeDBConnection() {
  //   await this.orderDetailServiceListDB.close();
  // }

  
  //selector === Equality operator $eq,Greater than operator $gt,Greater than or equal to operator $gte,Less than operator $lt,Less than or equal to operator $lte,In       operator $in,Not equal operator $ne,Not in operator $nin,Exists operator $exists,Type operator $type,Regex operator $regex// ex :name: { $eq: 'John' }

  public async getAll(filter) {
    
    

//   this.orderDetailServiceListDB.bulkGet({
  
// }, function (err, result) {
//   console.log('result',result)
//   if (err) { return console.log(err); }
//   // handle result
// });


// let rr=[];
//     this.orderDetailServiceListDB.allDocs( 
//       {include_docs: true,attachments: true},function(err, doc) {
//       rr=  doc.rows;
//       console.log('doc',doc.rows);
//       console.log('err',err);
//       console.log('rr',rr);
//       return rr;
// });



    //Query the documents and sort by the "pid" field
    await this.orderDetailServiceListDB.createIndex({
      index: {
        fields: ['pid','order_pid']
      }
    })
    filter.pid = {"$gte":null} // For sorting . not remove
    return this.orderDetailServiceListDB.find({
      selector: filter,
      //fields: ['_id','pid', 'order_pid'],
     // sort: [{ pid: 'asc' }],
     // sort: [{id: 'asc'}]
    }).then(async function (result) {
      // var filter_data = result.docs.filter(x=>x.order_pid==239);
      // console.log('filter_data',filter_data);
      // //this.closeDBConnection();
      // return filter_data;
       return result.docs;
      
    }).catch(function (err) {
      console.log(err);
    });

      // // Query the documents and sort by the "pid" field
      // return this.orderDetailServiceListDB.find({
      //   selector: filter,
      //   //sort: [{ pid: 'asc' }]
      // }).then(result => {
      //   return result.docs
      // }).catch(error => {
      //   console.log(error);
      // });
  }
 
  public async getById(id: string) {
    const result = await this.orderDetailServiceListDB.get(id);
    return result;
  }


  async getNextSequence(db, sequenceName) {
    try {
      const doc = await db.get(sequenceName);
      doc.current++;
      await db.put(doc);
      return doc.current;
    } catch (error) {
      if (error.status === 404) {
        // The sequence doesn't exist, so create a new one
        await db.put({
          _id: sequenceName,
          current: 1
        });
        return 1;
      } else {
        // There was a conflict while updating the sequence
        // Retry the operation by calling the function again
        return await this.getNextSequence(db, sequenceName);
      }
    }
  }
  
  
  
  async bulkAddOrUpdate(db, docs, fieldName) {
    const existingDocs = await db.find({
      selector: {
        [fieldName]: { $in: docs.map((doc) => doc[fieldName]) },
      },
    });

    const existingDocsMap = new Map();
    existingDocs.docs.forEach((doc) => {
      existingDocsMap.set(doc[fieldName], doc);
    });
    const docsWithIds = [];
    for (const doc of docs) {
      const existingDoc = existingDocsMap.get(doc[fieldName]);
      const datatoinsert: any = {};

      if (existingDoc) {
        // Update existing doc
        datatoinsert._id = existingDoc._id;
        datatoinsert._rev = existingDoc._rev;
        datatoinsert.pid = existingDoc.pid;

      } else {
        // Add new doc
        const sequence = await this.getNextSequence(this.sequenceDb, this.sequenceDocId);
        datatoinsert.pid = sequence;
      }

      datatoinsert.id = doc.id;
      datatoinsert.order_pid = doc.order_pid;
      datatoinsert.order_id = doc.order_id;
      datatoinsert.pricelookup_id = doc.pricelookup_id;
      datatoinsert.pricelookup_qty = doc.pricelookup_qty;
      datatoinsert.pricelookup_name = doc.pricelookup_name;
      datatoinsert.pricelookup_item_price = doc.pricelookup_item_price;
      datatoinsert.add_variation = doc.add_variation;
      datatoinsert.price_override_item_status = doc.price_override_item_status;
      datatoinsert.price_override_item_price = doc.price_override_item_price;
      datatoinsert.price_override_item_id = doc.price_override_item_id;
      datatoinsert.price_override_item_comment = doc.price_override_item_comment;
      datatoinsert.add_addon = doc.add_addon;
      datatoinsert.add_ingredient = doc.add_ingredient;
      datatoinsert.addon_variation_combine = doc.addon_variation_combine;
      datatoinsert.addon_ingredient_combine = doc.addon_ingredient_combine;
      datatoinsert.is_advance_ingredient = doc.is_advance_ingredient;
      datatoinsert.add_group_id = doc.add_group_id;
      datatoinsert.remove_group_id = doc.remove_group_id;
      datatoinsert.note = doc.note;
      datatoinsert.is_substitute = doc.is_substitute;
      datatoinsert.is_taken_for_new_item = doc.is_taken_for_new_item;
      datatoinsert.is_variation_pricelookup = doc.is_variation_pricelookup;
      datatoinsert.kitchen_department_store_id = doc.kitchen_department_store_id;
      datatoinsert.plu_type = doc.plu_type;
      datatoinsert.add_addons_ids = doc.add_addons_ids;
      datatoinsert.remove_addons_ids = doc.remove_addons_ids;
      datatoinsert.is_deleted = doc.is_deleted;
      datatoinsert.local_id = doc.local_id;
      datatoinsert.finalOriginalPrice = doc.finalOriginalPrice;
      datatoinsert.isCouponItem = doc.isCouponItem;
      datatoinsert.order_detail_categories = doc.order_detail_categories;
      datatoinsert.order_detail_tax = doc.order_detail_tax;
      datatoinsert.order_detail_add_add_ons = doc.order_detail_add_add_ons;
      datatoinsert.order_detail_remove_add_ons = doc.order_detail_remove_add_ons;
      datatoinsert.get_k_d_s_detail = doc.get_k_d_s_detail;

      
    

      datatoinsert.cal_price = doc.cal_price;
      datatoinsert.price = doc.price;
      
      docsWithIds.push(datatoinsert);


    }

    try {
      // Bulk add documents to the database


      const result = await db.bulkDocs(docsWithIds);


      // const sharedDataService = this.injector.get(SharedDataService);
      // sharedDataService.getOrderDetailsListArray(1);
      return result;
    } catch (error) {
      console.log('Error adding documents:', error);
    }
  }

  public async delete(id: string): Promise<void> {
    let doc;
    // Fetch the document to get the current _rev value
    try {
      doc = await this.orderDetailServiceListDB.get(id);
      // Delete the document using the current _id and _rev values
      try {
        await this.orderDetailServiceListDB.remove(id, doc._rev);
        // const sharedDataService = this.injector.get(SharedDataService);
        // sharedDataService.getOrderDetailsListArray(1);
        //console.log('Document deleted successfully!');
      } catch (err) {
        console.error('Error deleting document:', err);
      }
    } catch (err) {
      console.error('Error fetching document:', err);
      return;
    }
  }

  public async deleteDirect(data): Promise<void> {
    try {
      await this.orderDetailServiceListDB.remove(data._id, data._rev);
      // const sharedDataService = this.injector.get(SharedDataService);
      // sharedDataService.getOrderDetailsListArray(1);
    } catch (err) {
      console.error('Error fetching document:', err);
      return;
    }
  }



  public async dropAdd(data) {
    
    return await this.orderDetailServiceListDB.destroy().then(async () => {
      // Create a new database
      this.orderDetailServiceListDB = new PouchDB('orderDetailServiceList');
      return await this.bulkAddOrUpdate(this.orderDetailServiceListDB, data, "id");
    }).then(() => {
      //console.log('Data added to the database.');
      
      return 0;
    }).catch((error) => {
      console.error('Error:', error);
      return 0;
    });
  }





}
