import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb';
import pouchdbFind from 'pouchdb-find';

PouchDB.plugin(pouchdbFind);


@Injectable({
  providedIn: 'root'
})
export class PluMenuItemService {
  private pluMenuItemServiceListDB: any;
  private sequenceDb: any;
  private sequenceDocId = 'pluMenuItemServiceList_sequence';

  constructor() {
    this.pluMenuItemServiceListDB = new PouchDB('pluMenuItemServiceList')
    this.pluMenuItemServiceListDB.createIndex({
      index: {
        fields: ['pid', 'id']
      }
    })
    this.sequenceDb = new PouchDB('mydb_sequence');
  }

  public async use(fc, data) {
    if (fc == "G_DATA") {
      return this.getById(data._id)
    } else if (fc == "GA_DATA") {
      return this.getAll(data)
    } else if (fc == "IU_DATA") {
      return await this.bulkAddOrUpdate(this.pluMenuItemServiceListDB, data, "id");
    } else if (fc == "D_DATA") {
      return this.delete(data.id)
    } else if (fc == "DROP_ADD") {
      return await this.dropAdd(data);
    }
  }

  //selector === Equality operator $eq,Greater than operator $gt,Greater than or equal to operator $gte,Less than operator $lt,Less than or equal to operator $lte,In       operator $in,Not equal operator $ne,Not in operator $nin,Exists operator $exists,Type operator $type,Regex operator $regex// ex :name: { $eq: 'John' }

  public async getAll(filter) {
    
      // Query the documents and sort by the "pid" field
      return this.pluMenuItemServiceListDB.find({
        selector: filter
        //sort: [{ pid: 'asc' }]
      }).then(result => {
        return result.docs.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        });

        // return data.map((doc)=>{
        //   doc.pricelookup_qty = 0;
        //   doc.pricelookup_id = doc.id;
        //   doc.cal_price = 0.00;
        //   doc.addOnprice = 0.00;
        //   doc.pricelookup_name = doc.name;
        //   doc.pricelookup_name_language_2 = doc.display_name_language2??"";
        //   doc.price_override_item_status = 0;
        //   doc.price_override_item_price = 0;
        //   doc.price_override_item_id = 0;
        //   doc.price_override_item_comment = "";
        //   doc.selectionColor = false;
        //   doc.tax_include_price = doc.tax_include_price;
        //   doc.specialNote = ""
        //   doc.is_substitute = 0;
        //   doc.selectedAddOns = [];
        //   doc.selectedRemoveAddOns = [];
        //   doc.isCouponItem = false;
        //   doc.isSpecialOffer = false;
        //   return doc
        // })
      }).catch(error => {
        console.log(error);
      });
  }
 
  public async getById(id: string) {
    const result = await this.pluMenuItemServiceListDB.get(id);
    return result;
  }


  async getNextSequence(db, sequenceName) {
    try {
      const doc = await db.get(sequenceName);
      doc.current++;
      await db.put(doc);
      return doc.current;
    } catch (error) {
      if (error.status === 404) {
        // The sequence doesn't exist, so create a new one
        await db.put({
          _id: sequenceName,
          current: 1
        });
        return 1;
      } else {
        // There was a conflict while updating the sequence
        // Retry the operation by calling the function again
        return await this.getNextSequence(db, sequenceName);
      }
    }
  }
  
  
  
  async bulkAddOrUpdate(db, docs, fieldName) {
    const existingDocs = await db.find({
      selector: {
        [fieldName]: { $in: docs.map((doc) => doc[fieldName]) },
      },
    });

    const existingDocsMap = new Map();
    existingDocs.docs.forEach((doc) => {
      existingDocsMap.set(doc[fieldName], doc);
    });

    const docsWithIds = [];
    for (const doc of docs) {
      const existingDoc = existingDocsMap.get(doc[fieldName]);
      const datatoinsert: any = {};
      if (existingDoc) {
        // Update existing doc
        datatoinsert._id = existingDoc._id;
        datatoinsert._rev = existingDoc._rev;
        datatoinsert.pid = existingDoc.pid;

      } else {
        // Add new doc
        const sequence = await this.getNextSequence(this.sequenceDb, this.sequenceDocId);
        datatoinsert.pid = sequence;
      }
      datatoinsert.id = doc.id;
      datatoinsert.store_id = doc.store_id;
      datatoinsert.is_advance_ingredient = doc.is_advance_ingredient;
      datatoinsert.add_group_id = doc.add_group_id;
      datatoinsert.remove_group_id = doc.remove_group_id;
      datatoinsert.background_image = doc.background_image;
      datatoinsert.background_color = doc.background_color;
      datatoinsert.tax_include = doc.tax_include;
      datatoinsert.name = doc.name;
      datatoinsert.standard_price = doc.standard_price;
      datatoinsert.description = doc.description;
      datatoinsert.extra_operation = doc.extra_operation;
      datatoinsert.tax_include_price = doc.tax_include_price;
      datatoinsert.store_categories = doc.store_categories;
      datatoinsert.extra_tax = doc.extra_tax;
      datatoinsert.store_tax = doc.store_tax;
      datatoinsert.tender_profiles = doc.tender_profiles;
      datatoinsert.add_groups_addons = doc.add_groups_addons;
      datatoinsert.remove_groups_addons = doc.remove_groups_addons;
      datatoinsert.store_ingredients = doc.store_ingredients;
      datatoinsert.plu_type = doc.plu_type;
      datatoinsert.kitchen_department_store_id = doc.kitchen_department_store_id;
      datatoinsert.addgroup_option = doc.addgroup_option;
      datatoinsert.store_brands_id = doc.store_brands_id;
      datatoinsert.display_name_language2 = doc.display_name_language2;

      //new performance 
      datatoinsert.pricelookup_qty = 0;
      datatoinsert.pricelookup_id = doc.id;
      datatoinsert.cal_price = 0.00;
      datatoinsert.addOnprice = 0.00;
      datatoinsert.pricelookup_name = doc.name;
      datatoinsert.pricelookup_name_language_2 = doc.display_name_language2??"";
      datatoinsert.price_override_item_status = 0;
      datatoinsert.price_override_item_price = 0;
      datatoinsert.price_override_item_id = 0;
      datatoinsert.price_override_item_comment = "";
      datatoinsert.selectionColor = false;
      datatoinsert.tax_include_price = doc.tax_include_price;
      datatoinsert.specialNote = ""
      datatoinsert.is_substitute = 0;
      datatoinsert.selectedAddOns = [];
      datatoinsert.selectedRemoveAddOns = [];
      datatoinsert.isCouponItem = false;
      datatoinsert.isSpecialOffer = false;
      //new End

      if(doc.single_parent_group){
        doc.parent_id = doc.single_parent_group.pricelookup_id??0;
      }else if(doc.single_level_two_group){
        doc.parent_id = doc.single_level_two_group.single_parent_group.pricelookup_ref_id??0;
      }else{
        doc.parent_id = 0;
      }
      datatoinsert.parent_id = doc.parent_id;
      if(doc.parent_id == 0){
        datatoinsert.main_parent_id = 0;
      }else{
        var main_parent_found = docs?.find(x => x.id == doc.parent_id)
        if (main_parent_found) {
          if(main_parent_found.parent_id == 0){
            datatoinsert.main_parent_id = main_parent_found.id;
          }else{
            datatoinsert.main_parent_id = main_parent_found.parent_id;
          }
        }
      }      
      docsWithIds.push(datatoinsert);
    }
    try {
      // Bulk add documents to the database
      const result = await db.bulkDocs(docsWithIds);
      return result;
    } catch (error) {
      console.log('Error adding documents:', error);
    }
  }

  public async delete(id: string): Promise<void> {
    let doc;
    // Fetch the document to get the current _rev value
    try {
      doc = await this.pluMenuItemServiceListDB.get(id);
      // Delete the document using the current _id and _rev values
      try {
        await this.pluMenuItemServiceListDB.remove(id, doc._rev);
        //console.log('Document deleted successfully!');
      } catch (err) {
        console.error('Error deleting document:', err);
      }
    } catch (err) {
      console.error('Error fetching document:', err);
      return;
    }
  }

  

  public async dropAdd(data) {
    
    return await this.pluMenuItemServiceListDB.destroy().then(async () => {
      // Create a new database
      this.pluMenuItemServiceListDB = new PouchDB('pluMenuItemServiceList');
      return await this.bulkAddOrUpdate(this.pluMenuItemServiceListDB, data, "id");
    }).then(() => {
      //console.log('Data added to the database.');
      return 0;
    }).catch((error) => {
      console.error('Error:', error);
      return 0;
    });
  }



}
