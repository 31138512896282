import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb';
import pouchdbFind from 'pouchdb-find';

PouchDB.plugin(pouchdbFind);


@Injectable({
  providedIn: 'root'
})
export class CustomerAddressService {
  private customerAddressServiceListDB: any;
  private sequenceDb: any;
  private sequenceDocId = 'customerAddressServiceList_sequence';

  constructor() {
    this.customerAddressServiceListDB = new PouchDB('customerAddressServiceList')
    this.customerAddressServiceListDB.createIndex({
      index: {
        fields: ['pid', 'id','local_insert_item_number']
      }
    })
    this.sequenceDb = new PouchDB('mydb_sequence');
  }

  public async use(fc, data) {
    if (fc == "G_DATA") {
      return this.getById(data._id)
    } else if (fc == "GA_DATA") {
      return this.getAll(data)
    } else if (fc == "IU_DATA") {
      return await this.bulkAddOrUpdate(this.customerAddressServiceListDB, data, "local_insert_item_number");
    } else if (fc == "D_DATA") {
      return this.delete(data.id)
    } else if (fc == "DROP_ADD") {
      return await this.dropAdd(data);
    }
  }

  //selector === Equality operator $eq,Greater than operator $gt,Greater than or equal to operator $gte,Less than operator $lt,Less than or equal to operator $lte,In       operator $in,Not equal operator $ne,Not in operator $nin,Exists operator $exists,Type operator $type,Regex operator $regex// ex :name: { $eq: 'John' }

  public async getAll(filter) {
    
      // Query the documents and sort by the "pid" field

      return this.customerAddressServiceListDB.find({
        selector: filter,
        //sort: [{ pid: 'asc' }]
      }).then(async result => {
        const modifiedDocs = await Promise.all(result.docs.map(async doc => {
          const element = {
            ...doc,
          };
          if (element) {
            let add_str = '';
            if (element.house_no) {
              add_str += element.house_no;
            }
            if (element.society_name) {
              add_str += ", ";
              add_str += element.society_name;
            }
            if (element.city) {
              add_str += ", ";
              add_str += element.city;
            }
            if (element.state) {
              add_str += ", ";
              add_str += element.state;
            }
            if (element.pincode) {
              add_str += ", ";
              add_str += element.pincode;
            }
            element.customer_address_display = add_str;
          }
          return element;
        }));
        return modifiedDocs;
      }).catch(error => {
        console.log(error);
      });

      
  }
 
  public async getById(id: string) {
    const result = await this.customerAddressServiceListDB.get(id);
    return result;
  }


  async getNextSequence(db, sequenceName) {
    try {
      const doc = await db.get(sequenceName);
      doc.current++;
      await db.put(doc);
      return doc.current;
    } catch (error) {
      if (error.status === 404) {
        // The sequence doesn't exist, so create a new one
        await db.put({
          _id: sequenceName,
          current: 1
        });
        return 1;
      } else {
        // There was a conflict while updating the sequence
        // Retry the operation by calling the function again
        return await this.getNextSequence(db, sequenceName);
      }
    }
  }
  
  async bulkAddOrUpdate(db, docs, fieldName) {
    const existingDocs = await db.find({
      selector: {
        [fieldName]: { $in: docs.map((doc) => Number(doc[fieldName])) },
      },
    });

    const existingDocsMap = new Map();
    existingDocs.docs.forEach((doc) => {
      doc.local_insert_item_number = Number(doc.local_insert_item_number);
      existingDocsMap.set(doc[fieldName], doc);
    });

    const docsWithIds = [];
    for (const doc of docs) {
      doc.local_insert_item_number = Number(doc.local_insert_item_number);
      const existingDoc = existingDocsMap.get(doc[fieldName]);
      const datatoinsert: any = {};
      if (existingDoc) {
        // Update existing doc
        datatoinsert._id = existingDoc._id;
        datatoinsert._rev = existingDoc._rev;
        datatoinsert.pid = existingDoc.pid;

      } else {
        // Add new doc
        const sequence = await this.getNextSequence(this.sequenceDb, this.sequenceDocId);
        datatoinsert.pid = sequence;
      }
      if(!doc.local_insert_item_number) {
        var randomNumber = Math.floor(10000 + Math.random() * 90000);
        var timestamp = new Date().getTime();
        doc.local_insert_item_number = Number(randomNumber+ '' + timestamp)
      }
      datatoinsert.local_insert_item_number = Number(doc.local_insert_item_number);
      datatoinsert.id = doc.id;
      datatoinsert.user_pid = doc.user_pid;
      datatoinsert.user_id = doc.user_id;
      datatoinsert.set_default = doc.set_default;
      datatoinsert.type = doc.type;
      datatoinsert.house_no = doc.house_no;
      datatoinsert.society_name = doc.society_name;
      datatoinsert.city = doc.city;
      datatoinsert.state = doc.state;
      datatoinsert.pincode = doc.pincode;
      datatoinsert.phone = doc.phone;
      datatoinsert.lat = doc.lat;
      datatoinsert.lng = doc.lng;
      datatoinsert.isSync = doc.isSync;     

      docsWithIds.push(datatoinsert);
    }
    try {
      // Bulk add documents to the database
      const result = await db.bulkDocs(docsWithIds);
      return result;
    } catch (error) {
      console.log('Error adding documents:', error);
    }
  }

  public async delete(id: string): Promise<void> {
    let doc;
    // Fetch the document to get the current _rev value
    try {
      doc = await this.customerAddressServiceListDB.get(id);
      // Delete the document using the current _id and _rev values
      try {
        await this.customerAddressServiceListDB.remove(id, doc._rev);
        //console.log('Document deleted successfully!');
      } catch (err) {
        console.error('Error deleting document:', err);
      }
    } catch (err) {
      console.error('Error fetching document:', err);
      return;
    }
  }

  

  public async dropAdd(data) {
    
    return await this.customerAddressServiceListDB.destroy().then(async () => {
      // Create a new database
      this.customerAddressServiceListDB = new PouchDB('customerAddressServiceList');
      return await this.bulkAddOrUpdate(this.customerAddressServiceListDB, data, "id");
    }).then(() => {
      //console.log('Data added to the database.');
      return 0;
    }).catch((error) => {
      console.error('Error:', error);
      return 0;
    });
  }



}
